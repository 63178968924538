import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { TextareaAutosize } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";

export default function SendMailModal({ open, setOpen, business }) {
  const [mail, setMail] = useState({});
  const {sendPostFormData} = useApi()
  const [isFormActive, setIsFormActive] = useState(true)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMail({})
    setOpen(false);
  };

  const handleSendMail = (e) => {
    e.preventDefault()
    if (!isFormActive)
        return
    setIsFormActive(false)
    console.log("handleSendMail")
    sendPostFormData("/mail/customer-business/" + business.slug, mail , {isAuth: false})
        .then(response => {
            toast.success(response.data.message)
            handleClose()
        })
        .catch(err => {
            if (err.response.status == 422) {
                var errors = err.response.data.errors
                for (let key of Object.keys(errors)) {
                    toast.error(errors[key][0])
                }
            }
            else
                toast.error(err.message)
        })
        .finally(() => setIsFormActive(true))
  }

  return (
    <>
        <Dialog open={open} onClose={handleClose} fullWidth="true">
        <form onSubmit={handleSendMail} style={{opacity: isFormActive ? "1" : "0.3"}}>
          <DialogTitle>Contact Us</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="from_name"
              label="Name"
              type="text"
              value={mail.from_name}
              onChange={(e) => setMail({ ...mail, from_name: e.target.value })}
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="from_email"
              label="Email"
              type="email"
              value={mail.from_email}
              onChange={(e) => setMail({ ...mail, from_email: e.target.value })}
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="subject"
              label="Subject"
              type="text"
              value={mail.subject}
              onChange={(e) =>
                setMail({ ...mail, subject: e.target.value })
              }
              fullWidth
              variant="standard"
            />
            <TextField
              autoFocus
              margin="dense"
              id="message"
              label="Message"
              type="text"
              value={mail.message}
              multiline
              rows={5}
              onChange={(e) =>
                setMail({ ...mail, message: e.target.value })
              }
              fullWidth
              variant="standard"
            />
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} type="button">Cancel</Button>
            <Button type="submit">Send Mail</Button>
          </DialogActions>
          </form>
        </Dialog>
      
    </>
  );
}
