import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import { clearCart } from "../../../actions/cartAction";

export default function PaypalModal({ open, setOpen, order }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sendPostFormData } = useApi();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            Click on the button below to pay using PayPal
        </DialogTitle>
        <DialogContent>
          <PayPalButtons
            fundingSource="paypal"
            createOrder={(data, actions) => {
              return sendPostFormData("/paypal/create-order", {
                order_id: order.id
              })
                .then((data) => {
                  return data.data.id;
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
            onApprove={(data, actions) => {
              let payment_id = data.orderID;
              return sendPostFormData("/paypal/complete-order", {
                payment_id: payment_id,
              })
                .then(() => {
                  toast.success("Order placed successfully");
                  dispatch(clearCart())
                  navigate("/my-orders");
                })
                .catch((err) => toast.error(err.message));
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
