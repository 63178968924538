const firstWordUpperCase = (str) => {
  var strArray = str.split(" ");
  strArray = strArray.map((item) => firstLetterUppercase(item));
  str = strArray.join(" ");
  console.log(str);
  return str;
};

const firstLetterUppercase = (str) => {
  if (!str?.length) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export { firstWordUpperCase, firstLetterUppercase };
