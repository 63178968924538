import React from 'react'
import './Stores.css'
import resturant from '../assets/resturant.jpg'
import jewels from '../assets/jewels.jpg'
import grocery from '../assets/grocery.png'
import { Link } from 'react-router-dom'
// import kitchen from '../assets/kitchen king.png'

const Stores = () => {
    return (
        <div className='Store'>
            <div className="container">
                <div className="store-1">

                    <div className="btns">
                        <Link to='/restaurants'><button>Restaurant</button></Link>
                    </div>
                    <div className="image">
                        <img src={resturant} alt='rest' />
                    </div>
                </div>
                <div className="store-2">
                    <div className="btns">
                        <button>Grocery</button>
                    </div>
                    <div className="image">
                        <img src={grocery} alt="groc" />
                    </div>
                </div>
                <div className="store-3">
                    <div className="btns">
                        <button>Jewelry</button>
                    </div>
                    <div className="image">
                        <img src={jewels} alt="jewels" />
                    </div>
                </div>
            </div>


            {/* <div className="container">
                <button onClick={handleClick}> Restaurants </button>
                <button> Grocery </button>
                <button> Jewelry </button>
            </div>
            {rest && <div className="resturants">
                <div className="rest-container">
                    <div className="rest-img"><img src={kitchen} alt="" /></div>
                    <div className="rest-details">
                        <Link to="/restproducts"><button>Kitchen King</button></Link>
                        <p>Pickup & Delivery</p>
                    </div>
                </div>
            </div>} */}
        </div>
    )
}

export default Stores