import React, { useEffect, useState } from 'react'
import './Wallet.css'
import { useForm } from 'react-hook-form'
import useApi from '../../../hooks/useApi'
import { toast } from 'react-toastify'
import useAuth from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const Wallet = () => {
    const {t} = useTranslation()
    const [bitcoin_address, setBitcoinAddress] = useState("")
    const [ethereum_address, setEthereumAddress] = useState("")
    const [xrp_address, setXrpAddress] = useState("")
    const { register, handleSubmit } = useForm()
    const { sendGet, sendPostFormData } = useApi()
    const { currentUser } = useAuth()
    useEffect(() => {
        sendGet("/businesses/" + currentUser()?.business?.slug).then(response => {
            var data = response.data.data
            setBitcoinAddress(data.bitcoin_address)
            setEthereumAddress(data.ethereum_address)
            setXrpAddress(data.xrp_address)
        }).catch(err => {
            toast.error(err.message)
        })
    }, [])
    const saveWallet = (e) => {
        // console.log(data)
        e.preventDefault()
        var formData = new FormData()
        formData.append("bitcoin_address", bitcoin_address ? bitcoin_address: '')
        formData.append("ethereum_address", ethereum_address ? ethereum_address : '')
        formData.append("xrp_address", xrp_address ? xrp_address : '')
        sendPostFormData("/businesses/wallet", formData).then(response => {
            toast.success(response.data.message);
        })
    }
    return (
        <form onSubmit={saveWallet} className="panel walletBoard">
            <h1 className='title'>Digitale Wallets</h1>
            <hr />
            <div className="wallets-col">
                <div className="allWallets EveryCol">
                    <h2 className='subtitle'>Wallets</h2>

                    <div className="bitcoin">
                        <span>Bitcoin</span>
                    </div>
                    {/* <div className="ether">
                        <span>Ethereum</span>
                    </div>
                    <div className="xrp">
                        <span>XRP</span>
                    </div> */}
                    {/* <div className="csc">
                        <span>CryptoshareCredit</span> 
                    </div> */}
                </div>
                <div className="allAdresses EveryCol">
                    <h2 className='subtitle'>Wallet Address</h2>
                    <div className="bitcoin address-buttons">
                        <input placeholder="Add Bitcoin Wallet Address" {...register("bitcoin_address")} value={bitcoin_address} onChange={(e) => setBitcoinAddress(e.target.value)} />
                    </div>
                    {/* <div className="ether address-buttons">
                        <input placeholder="Add Ethereum Wallet Address" {...register("ethereum_address")} value={ethereum_address} onChange={(e) => setEthereumAddress(e.target.value)} />
                    </div>
                    <div className="xrp address-buttons">
                        <input placeholder="Add XRP Wallet Address" {...register("xrp_address")} value={xrp_address} onChange={(e) => setXrpAddress(e.target.value)} />
                    </div> */}
                    {/* <div className="csc address-buttons">
                        <button>Create CryptoshareCredit Wallet</button>
                     </div> */}
                </div>
                <div className="allBalance allAdresses EveryCol">
                    <h2 className='subtitle'>Create Wallets</h2>

                    <div className="ether balance">
                        <button><a href="https://trustwallet.com/bitcoin-wallet" target='_blank'>Create Bitcoin Wallet</a></button>
                    </div>
                    {/* <div className="ether balance">
                        <button><a href="https://trustwallet.com/ethereum-wallet" target='_blank'>Create Ethereum Wallet</a></button>
                    </div>
                    <div className="xrp balance">
                        <button><a href="https://trustwallet.com/xrp-wallet" target='_blank'>Create XRP Wallet</a></button>
                    </div> */}

                    {/* <div className="csc balance">
                    </div> */}
                </div>
            </div>
            <button className="pro-btn" type="submit">
            Done
            </button>
            <div className="text-center">
                <p>These is the Wallet that your Bitcoin Payment will be Received, if you decide to accept Bitcoin</p>
            </div>
        </form>
    )
}

export default Wallet;