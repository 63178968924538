import React from 'react'
import './Restpros.css'
import { Popup } from '../../../Example';
import { Popups } from '../../../Example';
import { useState } from 'react';
// import salad from '../assets/pro1.jpg'
// import food from '../assets/pro2.jpg'

const Restpros = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isPopupsVisible, setIsPopupsVisible] = useState(false);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);

    };
    const togglePopups = () => {
        setIsPopupsVisible(!isPopupsVisible);
    }

    return (
        <div className="full-rest">
            <div className='restpros'>
                <div className="products">
                    <div className="examples">
                        <div className="products-pro">
                            <div className="pro-1">
                                <div className="image-pro">
                                    {/* <img src={salad} alt="" /> */}
                                </div>
                                <div className="details-pro">
                                    <div className="cat-pro">
                                        <h4>Product Category:</h4>
                                        <span>Sides</span>
                                    </div>
                                    <span className='classic'>Salad</span>
                                    <div className="pro-pro">
                                        <h4>Product:</h4>
                                        <span>A Vegetable Salad</span>
                                    </div>
                                    <div className="price-pro">
                                        <h4>Price:</h4>
                                        <span>50.000 COP$</span>
                                        <span>=</span>
                                        <span>17.07 XRP</span>
                                    </div>
                                    <div className="method">
                                        <h4>Order Method:</h4>
                                        <span>Delivery & Pickup</span>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-pro">
                                <button onClick={togglePopup}>Preview</button>
                            </div>
                        </div>
                        {isPopupVisible && <Popup />}
                        <div className="products-pro">
                            <div className="pro-1">
                                <div className="image-pro">
                                    {/* <img src={food} alt="" /> */}
                                </div>
                                <div className="details-pro">
                                    <div className="cat-pro">
                                        <h4>Product Category:</h4>
                                        <span>Entree</span>
                                    </div>
                                    <span className='classic'>Shrimp Pasta</span>
                                    <div className="pro-pro">
                                        <h4>Product:</h4>
                                        <span>A Linguini Pasta with Shrimp <br /> and Vegetables</span>
                                    </div>
                                    <div className="price-pro">
                                        <h4>Price:</h4>
                                        <span>70.000 COP$</span>
                                        <span>=</span>
                                        <span>22.35 XRP</span>
                                    </div>
                                    <div className="method">
                                        <h4>Order Method:</h4>
                                        <span> Pickup</span>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-pro">
                                <button onClick={togglePopups}>Preview</button>
                            </div>
                        </div>
                        {isPopupsVisible && <Popups />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Restpros