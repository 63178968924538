import { useEffect } from "react";
import flag1 from "../../assets/usa.png";
import flag2 from "../../assets/co.png";
// import "./Gtranslation.css";

export default function Gtranslation() {
  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
    document.head.appendChild(scriptTag);
    // scriptTag.onload = () => {
    window.window.gtranslateSettings = {
      default_language: "en",
      languages: ["en", "es"],
      alt_flags: {
        "en": "usa",
        "es": "colombia"
      },
      wrapper_selector: ".gtranslate_wrapper",
    };
    // }
  }, []);
  return (
    <>
      <div className="gtranslate_wrapper">
        {/* <a
          className="nturl"
          data-gt-lang="en"
        >
          <img src={flag1} alt="English" title="English" />
        </a>
        <a
          className="nturl"
          data-gt-lang="es"
        >
          <img src={flag2} alt="Spanish" title="Spanish" />
        </a> */}
      </div>
    </>
  );
}
