import { BsFillCircleFill } from "react-icons/bs";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { addToCart } from "../../actions/cartAction";
import { useState } from "react";
import Order from "../Panels/OrderPage/Order";
import OrderDetailModal from "./OrderDetailModal";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    cursor: "pointer",
    backgroundColor: "rgba(187, 187, 187, 0.2)",
    transition: "background-color 0.2s ease-in-out"
  },
}));

const MyOrdersTable = ({ orders }) => {
  const [open, setOpen] = useState(false);
  const [selectOrder, setSelectedOrder] = useState({});
  const handleOrderDetailDisplay = (order) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  return (
    <>
      <OrderDetailModal order={selectOrder} open={open} setOpen={setOpen} />
      <TableContainer component={Paper} style={{ marginBottom: "30px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Order #</StyledTableCell>
              <StyledTableCell align="right">Order Id</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
              <StyledTableCell align="right">Delivery Address</StyledTableCell>
              <StyledTableCell align="right">Order Method</StyledTableCell>
              <StyledTableCell align="right">Payment Method</StyledTableCell>
              <StyledTableCell align="right">Payment Status</StyledTableCell>
              <StyledTableCell align="right">Date</StyledTableCell>
              <StyledTableCell align="right">Total</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <StyledTableRow key={order.id} onClick={() => handleOrderDetailDisplay(order)}>
                <StyledTableCell component="th" scope="row">
                  {++index}
                </StyledTableCell>
                <StyledTableCell align="right">{order.id}</StyledTableCell>
                <StyledTableCell align="right">
                  {order.order_status}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.delivery_address}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.delivery_method}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.payment_method}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.payment_status}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.created_at}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.order_total + order.tip}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyOrdersTable;
