import axios from 'axios';
import { useState, useEffect } from 'react';
import useAuth from './useAuth';

const API_ROOT = process.env.REACT_APP_API_HOST;

export default function useApi({ url, method, body = {}, callback = () => { }, fetchOnInit = true } = {}) {
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState(true);
    const { getToken } = useAuth();

    const fetchData = () => {
        const headers = {
            "Authorization": `Bearer ${getToken()}`
        }

        return axios({
            method: method,
            url: `${API_ROOT}${url}`,
            headers: headers,
            data: body
        }).then((res) => {
            setData(res.data);
            callback(res.data);
        })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setloading(false);
            });
    };

    const sendPost = (url, body, { isAuth = true } = {}) => {
        const headers = {}

        if (isAuth) {
            headers["Authorization"] = `Bearer ${getToken()}`
        }

        return axios({
            method: "post",
            url: `${API_ROOT}${url}`,
            headers: headers,
            data: body,
            responseType: 'json'
        });
    }

    const sendPostFormData = (url, formData, { isAuth = true } = {}) => {
        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        if (isAuth) {
            headers["Authorization"] = `Bearer ${getToken()}`
        }

        return axios({
            method: "post",
            url: `${API_ROOT}${url}`,
            headers: headers,
            data: formData,
            responseType: 'json'
        });
    }

    const sendPutFormData = (url, formData, { isAuth = true } = {}) => {
        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        if (isAuth) {
            headers["Authorization"] = `Bearer ${getToken()}`
        }

        return axios({
            method: "POST",
            url: `${API_ROOT}${url}`,
            headers: headers,
            data: {
                ...formData,
                "_method": "PUT"
            },
            responseType: 'json'
        });
    }

    const sendGet = (url, { isAuth = true } = {})=>{
        const headers = {}

        if (isAuth) {
            headers["Authorization"] = `Bearer ${getToken()}`
        }

        return axios({
            method: "get",
            url: `${API_ROOT}${url}`,
            headers: headers,
            responseType: 'json'
        });
    }

    const sendDelete = (url, { isAuth = true } = {})=>{
        const headers = {}

        if (isAuth) {
            headers["Authorization"] = `Bearer ${getToken()}`
        }

        return axios({
            method: "delete",
            url: `${API_ROOT}${url}`,
            headers: headers,
            responseType: 'json'
        });
    }

    useEffect(() => {
        // if (fetchOnInit)
            // fetchData();
    }, []);

    return { data, error, loading, fetchData, sendPost, sendGet,sendPostFormData, sendPutFormData, sendDelete };
}
