import React, { useEffect, useState } from "react";
import "./Profile.css";
import { IoMdContact } from "react-icons/io";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import useBusinessSignup from "../../../hooks/useBusinessSignup";

const Profile = () => {
  const { sendPutFormData } = useApi();
  const [business_logo, setBusinessLogo] = useState();
  const [isFormEnabled, setIsFormEnabled] = useState(true);
  const {getCurrentBusiness} = useBusinessSignup()
  const [business, setBusiness] = useState({})
  useEffect(fn => {
    getCurrentBusiness()
    .then(response => {
        setBusiness(response.data.data)
        console.log(business)
    })
    .catch(err => {
        console.log(err.message)
    });
  }, [])
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormEnabled) return;
    setIsFormEnabled(false);
    sendPutFormData("/businesses/update-logo", {
      business_logo: business_logo,
    })
      .then((response) => {
        setBusiness({
            ...business,
            logo_url: response.data.data.logo_url
        })
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setIsFormEnabled(true));
  };

  
  return (
    <div className="panel">
      <div className="profile-justify">
        { business.logo_url 
            ? (<img src={business.logo_url} style={{maxWidth: "500px"}} />) 
            : <IoMdContact className="prof-icon" />
        }
        
        <p>Upload a picture</p>
        <form onSubmit={handleSubmit} style={{ opacity: isFormEnabled ? 1 : 0.3 , display: "flex", flexDirection: "column", alignItems: "center"}}>
          <input
            type="file"
            onChange={(e) => setBusinessLogo(e.target.files[0])}
          />
          <button>Save Changes</button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
