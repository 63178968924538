import React, { useEffect, useState } from 'react'
import useApi from './useApi'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import useAuth from './useAuth';


export default function useBusinessSignup() {

    const { sendGet, sendPostFormData } = useApi({ fetchOnInit: false });

    const [inProcess, setInProcess] = useState(false);

    const { currentUser } = useAuth()

    const navigate = useNavigate();

    useEffect(() => {
        sendGet("/withoutloginuserinfo").then((data) => {
            console.log(data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const signup = (
        formData
    ) => {
        setInProcess(true);
        return sendPostFormData('/businessregister', formData).then((data) => {
            console.log(data);
            navigate("/login")
            setInProcess(false);
        }).catch((err) => {
            console.log(err);
            toast.error("failed to signup, try with different email");
            setInProcess(false);
        });
    }

    const getCurrentBusiness = () => {
        return sendGet("/businesses/" + currentUser()?.business?.slug)
    }

    const getCurrentLoggedInUser = () => {
        return sendGet("/users/logged-in")
    }

    return { signup, inProcess, getCurrentBusiness, getCurrentLoggedInUser }
}
