import React, { useState } from 'react'
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Landing from './components/Landing/Landing';
import Business from './components/Business/Business';
import Individual from './components/Individual/Individual';
import IndDashboard from './components/IndDashboard/IndDashboard';
import Login from './components/Login/Login';
import CusLogin from './components/Login/CusLogin';
import Example from './Example'
import BusDashboard from './components/BusDashboard/BusDashboard';
import Order from './components/Panels/OrderPage/Order';
import Payment from './components/Panels/Payment/Payment';
import Stores from './components/Stores/Stores';
import Restpros from './components/Stores/RestPros/Restpros';
import Resturant from './components/Stores/Resturant/Resturant.js';
import Checkout from './components/Stores/Checkout/Checkout';
import Checkout2 from './components/Stores/Checkout/Checkout2';
import MyCart from './components/Panels/MyCart/MyCart';
import MyOrders from "./components/Order/MyOrders.js"
import Gtranslation from './components/Panels/Gtranslation/Gtranslation.js';
// import CustomerServiceWidget from './components/Panels/CustomerServiceWidget/CustomerServiceWidget.js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';



const App = () => {
  useState(() => {
    document.title = "PayDigitale"
  }, [])
  return (
    <div>
      <Gtranslation/>
      {/* <CustomerServiceWidget/> */}
      <Routes>
        <Route path='/' element={<Landing/>} />
        <Route path='/business' element={<Business/>} />
        <Route path='/individual' element={<Individual/>} />
        <Route path='/login' element={<Login/>} />
        <Route path='/CusLogin' element={<CusLogin/>} />
        <Route path='/dashbus' element={<BusDashboard/>} />
        <Route path='/dashind' element={<IndDashboard/>} />
        {/* <Route path='/sidebar' element={<Example/>}/> */}
        {/* <Route path='/preview' element={<Order/>}/> */}
        <Route path='/payment' element={<Payment/>}/>
        <Route path='/stores' element={<Stores/>}/>
        <Route path='/restproducts' element={<Restpros/>}/>
        {/* <Route path='/restaurants/:slug' element={<Resturant/>} /> */}
        <Route path='/restaurants' element={<Resturant/>} />
        <Route path='/checkout/:product_slug' element={<Checkout/>} />
        <Route path='/checkouts' element={<Checkout2/>} />
        <Route path='/my-cart' element={<MyCart/>} />
        <Route path='/my-orders' element={<MyOrders/>} />
      </Routes>

    </div>

  )
}

export default App