import React from "react";
import "react-toastify/dist/ReactToastify.css";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, toast } from "react-toastify";
import { Provider } from "react-redux";
import { createStore } from "redux";
import allReducers from "./reducers";
import i18next from "i18next";
// import enTranslation from "./translations/en/translation.json";
// import esTranslation from "./translations/es/translation.json";
import { I18nextProvider } from "react-i18next";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = createStore(allReducers);

// For multi-language
i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    // en: {
    //   translation: enTranslation,
    // },
    // es: {
    //   translation: esTranslation,
    // },
  },
});

root.render(
  <HashRouter>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
      <PayPalScriptProvider options={{clientId: "ARt-QicrPl92vQ4t_gXuhYyDtnRhAiauIB4HqV4jPPlgkmwcY17u57p4q-5sEyQjS3lBMOOk_cfbmvD2"}}>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        </PayPalScriptProvider>
      </Provider>
    </I18nextProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
