import React, { useEffect, useState } from "react";
import "./MyCart.css";
import { useDispatch, useSelector } from "react-redux";
import OrderTable from "./OrderTable";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { clearCart } from "../../../actions/cartAction";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import CustomerLayout from "../../Layout/CustomerLayout";
import SimpleModal from "./SimpleModal";
import { getParameterByName } from "../../../helpers/BasicHelper";
import { getBusinessBitcoinAddressFromCart } from "../../../hooks/useCart";
import logo from "../../assets/digitalelogo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PaypalModal from "./PaypalModal";

const MyCart = () => {
  const { sendPostFormData, sendPutFormData } = useApi();
  const delivery_charge = 5000;
  const service_fee = 5000;
  const [is_delivery, setIsDelivery] = useState(true);
  const [order, setOrder] = useState({});
  const [formEnabled, setFormEnabled] = useState(true);
  const [isPaypalModalOpen, setPaypalModalOpen] = useState(false);
  const [delivery_method, setDeliveryMethod] = useState("delivery");
  const [choice, setChoice] = useState("create");
  const [delivery_address, setDeliveryAddress] = useState("");
  const [customer_id, setCustomerId] = useState("");
  const [payment_method, setPaymentMethod] = useState("cod");
  const [isSimpleModalOpen, setIsSimpleModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [checkout, setCheckout] = useState({});
  const [customer, setCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    delivery_address: "",
    phone: "",
    dob: "",
    cedula: "",
  });
  console.log("backend: ", process.env.REACT_APP_BACKEND_HOST);
  const paypal_endpoint = "https://api-m.sandbox.paypal.com";
  var shop_identifier = "eb4ca05322e908f6568ba2e19c81c31e";
  var public_order_id =
    "ZImnwR1wyhOwfcg7yQfMLKWzewY1vDYJqqANotEge1b1RIfLhQVkkykh4VNH0f25";
  var jwt_token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdXRoX3R5cGUiOiJWMl9BUEkiLCJwYXlsb2FkIjp7InB1YmxpY19vcmRlcl9pZCI6IlpJbW53UjF3eWhPd2ZjZzd5UWZNTEtXemV3WTF2RFlKcXFBTm90RWdlMWIxUklmTGhRVmtreWtoNFZOSDBmMjUifSwiZXhwIjoxNzAyNTcwNzI0LCJuYmYiOm51bGwsImlhdCI6bnVsbH0.6HxcH1A0M70mNJ1seXxoigcJhldlohP3qq5adSXvsJ8";
  const cart = useSelector((state) => state.cart);
  const [cartTotal, setCartTotal] = useState(0);
  const [tip, setTip] = useState(0);
  const handleClearCart = () => {
    console.log("dispatch clear cart");
    dispatch(clearCart());
  };
  const createSignatureHash = (signature) => {
    // Example
    // return
  };
  useEffect(() => {
    calculateTotal();
  }, [cart]);
  useEffect(() => {
    const payment_success = getParameterByName("payment_success");
    console.log("payment_success:", payment_success);
    if (payment_success != null) {
      if (payment_success == "true") {
        toast.success("Payment successful");
        dispatch(clearCart());
      } else if (payment_success == "false") {
        toast.error("Payment failed");
      }
    }
    calculateTotal();
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://checkout.wompi.co/widget.js";
    document.head.appendChild(scriptTag);

    scriptTag.onload = () => {};
  }, []);
  const calculateTotal = () => {
    var tempCartTotal = 0;
    cart.map((item) => {
      tempCartTotal += item.price * item.quantity;
    });
    setCartTotal(tempCartTotal);
  };

  const stripeCheckout = (order_id) => {
    var stripeUrl =
      process.env.REACT_APP_BACKEND_HOST + "/stripe/checkout/" + order_id;
    window.location.replace(stripeUrl);
  };

  const wompiCheckout = (order) => {
    sendPostFormData(
      "/wompi/signature-integrity",
      {
        amount: order.grand_total,
      },
      { isAuth: false }
    ).then((response) => {
      var data = response.data;
      var checkout = new window.window.WidgetCheckout({
        currency: "COP",
        amountInCents: data.amount_in_cents,
        reference: data.reference,
        publicKey: "pub_prod_nduwd3s5LVYsKqkIzZ3MJAK1GVePAZKn",
        signature: {
          integrity: data.hashed_signature,
        },
      });
      checkout.open(function (result) {
        var transaction = result.transaction;
        if (transaction.status != "DECLINED") {
          dispatch(clearCart());
          updateOrderPaymentStatus(order.id, transaction);
        }

        console.log("Transaction ID: ", transaction.id);
        console.log("Transaction object: ", transaction);
      });
    });
  };

  const handleTip = (e) => {
    if (e.target.value >= 0) setTip(e.target.value);
  };
  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
  };

  const createOrder = (e) => {
    e.preventDefault();
    // var paypal_buttons = window.document.getElementsByClassName("paypal-button");
    // console.log(paypal_buttons)
    // return
    if (cart.length < 1) {
      toast.error("Cart is empty. Add something to it first");
      return;
    }
    // createOrderLogic(null, 1, () => {
    //     // console.log("result: ", result);
    //     createOrderLogic(null, 0);
    // });
    // return
    createOrderLogic();
  };
  const updateOrderPaymentStatus = (order_id, result = null) => {
    sendPutFormData(
      `/orders/${order_id}/payment-status`,
      {
        payment_status: "paid",
        transaction_detail: result,
      },
      { isAuth: false }
    )
      .then((response) => {
        toast.success("Order placed successfully");
        navigate("/my-orders");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const getFormDataForOrderCreation = (transaction_detail = null) => {
    var items = [];
    for (let product of cart) {
      var item = {
        product_id: product.id,
        quantity: product.quantity,
      };
      items.push(item);
    }
    var form_data = {
      delivery_method: delivery_method,
      tip: tip,
      delivery_address: delivery_address,
      delivery_charge: is_delivery ? delivery_charge : 0,
      service_fee: service_fee,
      business_id: cart[0].business?.id,
      transaction_detail: transaction_detail,
      items: items,
      payment_method: payment_method,
    };
    if (choice == "enter") form_data["customer_id"] = customer_id;
    else form_data["customer"] = customer;
    return form_data;
  };
  const createOrderLogic = (
    transaction_detail,
    test,
    funcOnTestPass = null
  ) => {
    if (!formEnabled) return;
    setFormEnabled(false);
    var form_data = getFormDataForOrderCreation();
    console.log(form_data);
    // if (choice == "enter") form_data["customer_id"] = customer_id;
    // else form_data["customer"] = customer;
    console.log(form_data);
    sendPostFormData("/orders", form_data, { isAuth: false })
      .then((response) => {
        // if (test == 1) {
        //   funcOnTestPass()
        //   return;
        // }
        var order = response.data.data;
        setOrder(order);
        console.log(response.data.data);
        if (payment_method == "bitcoin") {
          console.log("payment_method == bitcoin");
          window.open(
            "https://checkout.opennode.com/" +
              order.transaction_detail.id +
              "?ln=1",
            "_blank",
            "referrer"
          );
          setIsSimpleModalOpen(true);
          dispatch(clearCart());
        } else if (payment_method == "credit") {
          // var amount = (+cartTotal + +tip) * 100;
          wompiCheckout(response.data.data);
          // stripeCheckout(order.id)
          return;
        } else if (payment_method == "cod") {
          toast.success(response.data.message);
          dispatch(clearCart());
        } else if (payment_method == "paypal") {
          setPaypalModalOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 422)
          for (let key of Object.keys(err.response.data.errors)) {
            toast.error(err.response.data.errors[key][0]);
          }
        else toast.error(err.response.data.message);
      })
      .finally(() => {
        setFormEnabled(true);
      });
  };

  return (
    <>
      <SimpleModal open={isSimpleModalOpen} setOpen={setIsSimpleModalOpen} />
      <PaypalModal
        open={isPaypalModalOpen}
        setOpen={setPaypalModalOpen}
        order={order}
      />
      <CustomerLayout>
        <form
          // action={process.env.REACT_BACKEND_HOST + "/stripe/checkout"}
          // method="GET"
          onSubmit={createOrder}
          className="large-container"
          style={{ opacity: formEnabled ? 1 : 0.3 }}
          disabled
        >
          <div className="Order">
            <OrderTable cart={cart} />
            <div className="customerChoices">
              <label htmlFor="choice" className="choices">
                <strong>Choose an option:</strong>
              </label>
              <br />
              <select id="choice" onChange={(e) => setChoice(e.target.value)}>
                <option
                  value="enter"
                  selected={choice == "enter" ? true : false}
                >
                  Enter Customer ID
                </option>
                <option
                  value="create"
                  selected={choice == "create" ? true : false}
                >
                  Create Customer ID
                </option>
              </select>
            </div>
            {choice === "enter" ? (
              <>
                <label htmlFor="CustID" className="choices">
                  Enter Customer Email: <sup>*</sup>
                </label>
                <br />
                <input
                  type="text"
                  id="CustID"
                  value={customer_id}
                  onChange={(e) => setCustomerId(e.target.value)}
                  placeholder="Enter your Customer Email"
                  style={{ marginBottom: "10px" }}
                  required
                />
                <br />
                {is_delivery && (
                  <>
                    <label htmlFor="delivery_address" className="choices">
                      Delivery Address: <sup>*</sup>
                    </label>
                    <br />
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GMAP_API_KEY}
                      onPlaceSelected={(data) => {
                        console.log(data);
                        setDeliveryAddress(data.formatted_address);
                      }}
                      options={{
                        types: "city",
                        componentRestrictions: { country: "co" },
                      }}
                      style={{ marginBottom: "10px" }}
                      required
                    />
                  </>
                )}

                <div className="payment">
                  <label>
                    Payment Method <sup>*</sup>
                  </label>{" "}
                  <br />
                  <div>
                    <div>
                      <input
                        checked={payment_method == "cod"}
                        type="radio"
                        value="cod"
                        name="payment_method"
                        onChange={() => setPaymentMethod("cod")}
                      />
                      <span>Cash</span>
                    </div>
                    <div>
                      <input
                        checked={payment_method == "credit"}
                        type="radio"
                        value="credit"
                        name="payment_method"
                        onChange={() => setPaymentMethod("credit")}
                      />
                      <span>Debit/Credit Card</span>
                    </div>
                    <div>
                      <input
                        checked={payment_method == "paypal"}
                        type="radio"
                        value="paypal"
                        name="payment_method"
                        onChange={() => setPaymentMethod("paypal")}
                      />
                      <span>Paypal</span>
                    </div>
                    {getBusinessBitcoinAddressFromCart() && (
                      <div className="sec-inp">
                        <input
                          checked={payment_method == "bitcoin"}
                          type="radio"
                          value="bitcoin"
                          name="payment_method"
                          // disabled
                          onClick={() => setPaymentMethod("bitcoin")}
                        />
                        <span className="spanImg">
                          {" "}
                          <span>Pay with Bitcoin</span>
                        </span>
                        <div className="account">
                          {/* {paymentOpt === "bitcoin" && <Account />} */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div>
                  <label>
                    First Name <sup>*</sup>
                  </label>{" "}
                  <br />
                  <input
                    id="customer_first_name"
                    value={customer.first_name}
                    onChange={(e) => {
                      setCustomer({ ...customer, first_name: e.target.value });
                      console.log(customer);
                    }}
                    type="text"
                    placeholder="Enter your first name here"
                    required
                  />
                </div>
                <div>
                  <label>
                    Last Name <sup>*</sup>
                  </label>{" "}
                  <br />
                  <input
                    id="customer_last_name"
                    value={customer.last_name}
                    onChange={(e) =>
                      setCustomer({ ...customer, last_name: e.target.value })
                    }
                    type="text"
                    placeholder="Enter your last name here"
                    required
                  />
                </div>
                <div>
                  <label>
                    Email <sup>*</sup>
                  </label>{" "}
                  <br />
                  <input
                    id="customer_email"
                    type="email"
                    value={customer.email}
                    onChange={(e) =>
                      setCustomer({ ...customer, email: e.target.value })
                    }
                    placeholder="Enter your Email here"
                    required
                  />
                </div>
                <div>
                  {is_delivery && (
                    <>
                      <label>
                        Delivery Address <sup>*</sup>
                      </label>{" "}
                      <br />
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GMAP_API_KEY}
                        onChange={(e) => {
                          console.log(e);
                          setCustomer({
                            ...customer,
                            delivery_address: e.target.value,
                          });
                        }}
                        onPlaceSelected={(data) => {
                          setCustomer({
                            first_name: document.getElementById(
                              "customer_first_name"
                            ).value,
                            last_name:
                              document.getElementById("customer_last_name")
                                .value,
                            email:
                              document.getElementById("customer_email").value,
                            delivery_address: data.formatted_address,
                            phone:
                              document.getElementById("customer_phone").value,
                            dob: document.getElementById("customer_dob").value,
                            cedula:
                              document.getElementById("customer_cedula").value,
                          });
                        }}
                        options={{
                          types: "city",
                          componentRestrictions: { country: "co" },
                        }}
                        required
                      />
                    </>
                  )}
                </div>
                <div>
                  <label>
                    Phone # <sup>*</sup>
                  </label>{" "}
                  <br />
                  <PhoneInput
                    inputStyle={{ width: "402px" }}
                    inputProps={{ id: "customer_phone" }}
                    country={"co"}
                    excludeCountries={[
                      "af",
                      "by",
                      "bi",
                      "cn",
                      "cu",
                      "er",
                      "ir",
                      "ly",
                      "ml",
                      "mm",
                      "ni",
                      "kp",
                      "ps",
                      "ru",
                      "sd",
                      "sy",
                      "ua",
                      "ve",
                      "ye",
                      "zw",
                    ]}
                    value={customer.phone}
                    onChange={(phone) =>
                      setCustomer({ ...customer, phone: phone })
                    }
                    placeholder="Input phone number"
                    required
                  />
                </div>
                {/* <div className='quantity-order'>
            <label>Product Quantity <sup>*</sup></label> <br />
            <input type="number" required />
        </div> */}
                {/* <div className="quantity-order">
                  <label>Date of Birth</label> <br />
                  <input
                    id="customer_dob"
                    type="date"
                    value={customer.dob}
                    onChange={(e) =>
                      setCustomer({ ...customer, dob: e.target.value })
                    }
                  />
                </div>
                <div className="quantity-order">
                  <label>Cedula# (optional)</label> <br />
                  <input
                    id="customer_cedula"
                    type="number"
                    value={customer.cedula}
                    onChange={(e) =>
                      setCustomer({ ...customer, cedula: e.target.value })
                    }
                  />
                </div> */}
                <div className="payment">
                  <label>
                    Payment Method <sup>*</sup>
                  </label>{" "}
                  <br />
                  <div>
                    <div>
                      <input
                        checked={payment_method == "cod"}
                        type="radio"
                        value="cod"
                        name="payment_method"
                        onChange={() => setPaymentMethod("cod")}
                      />
                      <span>Cash</span>
                    </div>
                    <div>
                      <input
                        checked={payment_method == "credit"}
                        type="radio"
                        value="credit"
                        name="payment_method"
                        onChange={() => setPaymentMethod("credit")}
                      />
                      <span>Debit/Credit Card</span>
                    </div>
                    <div>
                      <input
                        checked={payment_method == "paypal"}
                        type="radio"
                        value="paypal"
                        name="payment_method"
                        onChange={() => setPaymentMethod("paypal")}
                      />
                      <span>Paypal</span>
                    </div>
                    {/* <div className="sec-inp">
                      <input
                        checked={payment_method == "bitcoin"}
                        type="radio"
                        value="bitcoin"
                        name="payment_method"
                        // disabled
                        onClick={() => setPaymentMethod("bitcoin")}
                      />
                      <span className="spanImg">
                        {" "}
                        <span>Pay with Bitcoin</span>
                      </span>
                      <div className="account">
                        
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="terms">
                  <p>
                    {" "}
                    <input type="checkbox" required /> I Agree to{" "}
                    <a href="https://docdro.id/rawVjvg">Terms & Conditions</a>,{" "}
                    <a href="https://www.docdroid.net/5gwsBLX/anti-money-laundering-cryptosharepay-1-1-pdf">
                      AML
                    </a>{" "}
                    & <a href="https://docdro.id/astXB3F">Privacy Policy</a>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="summary">
            <div className="logo-image">
              <div className="imageee">
                <img src={logo} alt="" />
              </div>
            </div>
            <div>
              <label htmlFor="delivery">
                Delivery Method <sup>*</sup>
              </label>{" "}
              <br />
              <select
                value={delivery_method}
                onChange={(e) => {
                  setDeliveryMethod(e.target.value);
                  setIsDelivery(e.target.value == "delivery");
                }}
              >
                <option value="delivery">Delivery</option>
                <option value="pickup">Pickup</option>
              </select>
            </div>
            <div className="order-summary">
              <h3>Summary</h3>
              <hr />
              <div className="summary-det">
                <div>
                  <span>Total: </span>
                  <span>{cartTotal}</span>
                </div>
              </div>
              {/* <div className="order-spans">
              <span>Delivery Cost:</span>
              <span>10.000 COP$</span>
            </div> */}
              <div className="order-spans">
                <span>Add Tip:</span>
                <span>
                  <input
                    type="number"
                    min={0}
                    value={tip}
                    placeholder="0.000 COP$"
                    onChange={handleTip}
                  />
                </span>
              </div>
              {is_delivery && (
                <div
                  className="order-spans"
                  style={{ opacity: is_delivery ? 1 : 0.5 }}
                >
                  <span>
                    {/* <input type="checkbox" checked={is_delivery} onChange={(e) => {setIsDelivery(!is_delivery)}} style={{width: "20px", border: "1px solid red", padding: 0, margin: 0}} /> */}
                    <span>Delivery:</span>
                  </span>
                  <span>{delivery_charge}</span>
                </div>
              )}
              <div
                  className="order-spans"
                >
                  <span>
                    {/* <input type="checkbox" checked={is_delivery} onChange={(e) => {setIsDelivery(!is_delivery)}} style={{width: "20px", border: "1px solid red", padding: 0, margin: 0}} /> */}
                    <span>Service Fee:</span>
                  </span>
                  <span>{service_fee}</span>
                </div>

              <div className="order-spans">
                <span className="total">Grand Total:</span>
                <span className="total">
                  {+cartTotal +
                    +tip +
                    +service_fee +
                    (is_delivery ? delivery_charge : 0) +
                    " COP$"}
                </span>
              </div>
            </div>

            <div className="order-btn">
              <button>Submit Order</button>
            </div>
            <div className="order-btn">
              <button
                type="button"
                style={{ background: "#F60808" }}
                onClick={handleClearCart}
              >
                Clear Cart
              </button>
            </div>
          </div>
        </form>
        {/* <iframe
        id="PIGI"
        width="100%"
        src={`https://api.boldcommerce.com/checkout/storefront/${shop_identifier}/${public_order_id}/payments/iframe?token=${jwt_token}`}
      /> */}
      </CustomerLayout>
    </>
  );
};

export default MyCart;
