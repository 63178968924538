import React from 'react';
import './Bcredit.css'
import { AiFillCheckCircle } from 'react-icons/ai'
import { BsFillCircleFill } from 'react-icons/bs'
import './Bcredit.css'

import { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
    const targetTime = 10 * 60; // 10 minutes in seconds
    const [timeLeft, setTimeLeft] = useState(targetTime);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const targetTime = 10 * 60;
        if (timeLeft === 0) {
            setTimeLeft(targetTime);
        }
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <span>
            {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
        </span>
    );
};







export const Charts = () => {
    return (
        <div className="panel Bcredit">

            <h1 className='title'>Orders</h1>
            <hr />
            <div>
                <h2>Orders List</h2>
                <div className="flex-keys">
                    <div><span><AiFillCheckCircle className='check' /></span> <br /> <span>Closed</span></div>
                    <div><span><BsFillCircleFill className='circle' /></span> <br /> <span>Preparing</span></div>
                    <div><span><BsFillCircleFill className='circle red' /></span> <br /> <span>Canceled</span> </div>
                </div>
                <div className="table">
                    <div className="table-flex">
                        <h3>Order#</h3>
                    </div>
                    <div className="table-flex">
                        <h3>Product#</h3>
                    </div>
                    <div className="table-flex">
                        <h3>Product Title</h3>
                    </div>
                    {/* <div className="table-flex">
                        <h3>Table</h3>
                    </div> */}
                    <div className="table-flex">
                        <h3>Time</h3>
                    </div>
                    <div className="table-flex">
                        <h3>Status</h3>
                    </div>
                    {/* <div className="table-flex">
                    <h3>Cryptoshare <br /> Pay Username</h3>
                </div> */}
                    <div className="table-flex">
                        <h3>Delivery Address</h3>
                    </div>
                    <div className="table-flex">
                        <h3>Payment Method</h3>
                    </div>
                    <div className="table-flex">
                        <h3>Price COP$</h3>
                    </div>
                    <div className="table-flex">
                        <h3>Order Code</h3>
                    </div>

                </div>
                <div className="table-details">
                    <div className="details-flex">
                        <span>01</span>
                        <span>02</span>
                    </div>
                    <div className="details-flex">
                        <span>1</span>
                        <span>2</span>
                    </div>
                    <div className="details-flex">
                        <span>Vegetable Salad</span>
                        <span>Linguini Pasta with Shrimp</span>
                    </div>

                    {/* <div className="details-flex">
                        <span>06</span>
                        <span>N/A</span>
                    </div> */}
                    <div className="details-flex">
                        <span>10 Minutes <br /> 23 seconds <br />Closed Complete</span>
                        <CountdownTimer />
                    </div>
                    <div className="details-flex">
                        <span><AiFillCheckCircle className='check' /></span>
                        <span><BsFillCircleFill className='circle' /></span>
                    </div>
                    {/* <div className="details-flex">
                    <span>man@crypto <br /> sharepay.com</span>
                    <span>woman@crypto <br /> sharepay.com</span>
                </div> */}
                    <div className="details-flex">
                        <span>N/A</span>
                        <span>Calle 8 #4-9 Tolu, Sucre</span>
                    </div>
                    <div className="details-flex">
                        <span>XRP</span>
                        <span>XRP</span>
                    </div>
                    <div className="details-flex">
                        <span>50.000</span>
                        <span>70.000</span>
                    </div>
                    <div className="details-flex">
                        <span>ABC-123</span>
                        <span>XYZ-987</span>
                    </div>

                </div>
            </div>
        </div>
    )
}

const Chart = ({ orders }) => {
    return (
        <div>
            <h2>Orders List</h2>
            <div className="flex-keys">
                <div><span><AiFillCheckCircle className='check' /></span> <br /> <span>Closed</span></div>
                <div><span><BsFillCircleFill className='circle oranged' /></span> <br /> <span>Delivering</span></div>
                <div><span><BsFillCircleFill className='circle' /></span> <br /> <span>Preparing</span></div>
                <div><span><BsFillCircleFill className='circle red' /></span> <br /> <span>Canceled</span> </div>
            </div>
            <div className="table">
                <div className="table-flex">
                    <h3>Order#</h3>
                </div>
                <div className="table-flex">
                    <h3>Product#</h3>
                </div>
                <div className="table-flex">
                    <h3>Product Title</h3>
                </div>
                <div className="table-flex">
                    <h3>Order ID</h3>
                </div>
                <div className="table-flex">
                    <h3>Time</h3>
                </div>
                <div className="table-flex">
                    <h3>Status</h3>
                </div>
                <div className="table-flex">
                    <h3>Customer Email</h3>
                </div>
                <div className="table-flex">
                    <h3>Delivery Address</h3>
                </div>
                <div className="table-flex">
                    <h3>Payment Method</h3>
                </div>
                <div className="table-flex">
                    <h3>Price COP$</h3>
                </div>
                <div className="table-flex">
                    <h3>Order Code</h3>
                </div>

            </div>
            <div className="table-details">
                <div className="details-flex">
                    <span>01</span>
                    <span>02</span>
                </div>
                
                <div className="details-flex">
                    <span>1</span>
                    <span>2</span>
                </div>
                <div className="details-flex">
                    <span>Vegetables & Salad</span>
                    <span>Shrimp Pasta & Vegetables</span>
                </div>

                <div className="details-flex">
                    <span>67698109</span>
                    <span>67698159</span>
                </div>
                <div className="details-flex">
                    <span>10 Minutes <br /> 23 seconds <br />Closed Complete</span>
                    <CountdownTimer />
                </div>
                <div className="details-flex">
                    <span><BsFillCircleFill className='circle oranged'/></span>
                    <span><BsFillCircleFill className='circle' /></span>
                </div>
                <div className="details-flex">
                    {/* <span>man@crypto <br /> sharepay.com</span>
                    <span>woman@crypto <br /> sharepay.com</span> */}
                </div>
                <div className="details-flex">
                    <span>N/A</span>
                    <span>Calle 8 #4-9 Tolu, Sucre</span>
                </div>
                <div className="details-flex">
                    <span>XRP</span>
                    <span>XRP</span>
                </div>
                <div className="details-flex">
                    <span>50.000</span>
                    <span>70.000</span>
                </div>
                <div className="details-flex">
                    <span>ABC-123</span>
                    <span>XYZ-987</span>
                </div>

            </div>
        </div>
    );
};

export default Chart;
