const addToCart = (product, quantity) => {
    return {
        type: "ADD_CART",
        payload: {
            product,
            quantity
        }
    }
}

const clearCart = () => {
    return {
        type: "CLEAR_CART"
    }
}

export {addToCart, clearCart}