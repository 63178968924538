import React, { useState } from "react";
import "./Login.css";
import { FaEnvelope, FaKey } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import useLogin from "../../hooks/useLogin";

import { UserTypes } from "../../const";
import Gtranslation from "../Panels/Gtranslation/Gtranslation";

const CusLogin = () => {
  const { register, handleSubmit } = useForm();
  const { otpSent, sendOtp, sending, loginWithOtp } = useLogin();

  const [userType, setUserType] = useState(UserTypes.Customer);

  const sendOtpOrLogin = (data, event) => {
    const buttonName = event.nativeEvent.submitter.name;

    if (data.hasOwnProperty("loginCode")) {
      loginWithOtp(data.email, data.loginCode, userType);
    } else {
      sendOtp(data.email, userType);
    }
  };
  return (
    <>
      <Gtranslation />
      <div className="login-form">
        <form onSubmit={handleSubmit(sendOtpOrLogin)}>
          <div className="MainTitle">
            <h1>Digitale - Login</h1>
            <p>Please type your credentials</p>
          </div>
          {/* <div className='radio-btns'>
                    <input type="radio" checked={userType === UserTypes.Business} onChange={changeUserType(UserTypes.Business)} />
                    <label >Login as a Customer</label>
                </div> */}
          {/* <div className='radio-btns'>
                    <input type="radio" checked={userType === UserTypes.Individual} onChange={changeUserType(UserTypes.Individual)} />
                    <label >Login as an Individual</label>
                </div> */}
          <div className="email-box">
            <label htmlFor="email">Customer email</label> <br />
            <div className="with-icon">
              <FaEnvelope className="icon" />
              <input
                id="email"
                type="text"
                placeholder="example@gmail.com"
                {...register("email")}
                required
              />
            </div>
          </div>
          <button type="submit" disabled={sending} name="sendOtpBtn">
            {sending ? (
              <span>Sending...</span>
            ) : (
              <span>Request New One Time Login Code</span>
            )}
          </button>

          {otpSent ? (
            <>
              <div className="email-box">
                <label htmlFor="email">Login Code</label> <br />
                <div className="with-icon">
                  <FaKey className="icon" />
                  <input
                    type="text"
                    placeholder="6 digit login code"
                    {...register("loginCode")}
                  />
                </div>
              </div>
              <button type="submit" disabled={sending} name="loginBtn">
                {sending ? <span>please wait...</span> : <span>Login</span>}
              </button>
            </>
          ) : (
            <></>
          )}
        </form>
      </div>
    </>
  );
};

export default CusLogin;
