import React, { useEffect } from "react";
import { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import "./Setting.css";
import useBusinessSignup from "../../../hooks/useBusinessSignup";
import { toast } from "react-toastify";
import useApi from "../../../hooks/useApi";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function CedulaError() {
  return <p className="FieldError">Both Cedula Should be exactly Same.</p>;
}
export const SettingsInd = () => {
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [cedula, setcedula] = useState("");
  const [ccedula, setccedula] = useState("");
  const [country, setcountry] = useState("country");
  const [bday, setbday] = useState("");
  const [check, setcheck] = useState("");
  function handleCheck(e) {
    setcheck(e.target.checked);
  }
  function submitHandler(e) {
    e.preventDefault();
    alert("Your account is created!");
  }
  function isFormValid() {
    return fullname && email && cedula && ccedula && country && bday && check;
  }
  return (
    <div className="panel settings">
      <h1 className="title">Settings Ind</h1>
      <hr />
      <form onSubmit={submitHandler}>
        <div>
          <label htmlFor="fullname">
            Full legal name <sup>*</sup>
          </label>{" "}
          <br />
          <input
            id="fullname"
            type="text"
            placeholder="Your full legal name"
            value={fullname}
            onChange={(e) => {
              setfullname(e.target.value);
            }}
          />
        </div>

        <div>
          <label htmlFor="email">
            Email<sup>*</sup>
          </label>{" "}
          <br />
          <div className="with-icon">
            <FaEnvelope className="icon" />
            <input
              id="email"
              type="email"
              placeholder="example@company.com"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
          </div>
          <span className="FieldError">
            (Email Address is your Digitale Username)
          </span>
        </div>
        <div>
          <label htmlFor="email">
            Phone#<sup>*</sup>
          </label>{" "}
          <br />
          <div className="with-icon">
            <BsFillTelephoneFill className="icon" />
            <input id="email" type="number" placeholder="+00 000 00000" />
          </div>
          <span className="FieldError">
            (If you leave Blank, Pin Codes will be sent to Email Only)
          </span>
        </div>
        <div>
          <label htmlFor="cedula">Cedula #</label> <br />
          <input
            id="cedula"
            type="text"
            placeholder="Your identity document number"
            value={cedula}
            onChange={(e) => {
              setcedula(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="cedula2">Confirm Cedula #</label> <br />
          <input
            id="cedula2"
            type="text"
            placeholder="Confirm your identity document number"
            value={ccedula}
            onChange={(e) => {
              setccedula(e.target.value);
            }}
          />
          {cedula !== ccedula ? <CedulaError /> : null}
        </div>
        <div>
          <label htmlFor="country">
            Country <sup>*</sup>
          </label>{" "}
          <br />
          <select
            name="country"
            id="country"
            value={country}
            onChange={(e) => {
              setcountry(e.target.value);
            }}
          >
            <option value="country">Country</option>
            <option value="colombia">Colombia</option>
          </select>
        </div>
        <div>
          <label htmlFor="bday">
            Birthday <sup>*</sup>
          </label>{" "}
          <br />
          <input
            id="bday"
            type="date"
            value={bday}
            onChange={(e) => {
              setbday(e.target.value);
            }}
          />
        </div>
        <div>
          <input
            className="check"
            type="checkbox"
            checked={check}
            onChange={handleCheck}
          />
          <span>
            I Agree to Terms & Conditions, AML, and Jurisdiction Policy.
          </span>
        </div>
        <div>
          <button type="submit" disabled={!isFormValid()}>
            Sign up
          </button>
        </div>
      </form>
    </div>
  );
};

const Setting = () => {
  const [check, setcheck] = useState({
    value: "",
    isTouched: false,
  });

  const { sendPutFormData } = useApi();
  function handleCheck(e) {
    setcheck(e.target.checked);
  }
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const { getCurrentLoggedInUser } = useBusinessSignup();
  const [business_logo, setBusinessLogo] = useState();
  const apiKey = process.env.REACT_APP_GMAP_API_KEY;
  const [address, setAddress] = useState();
  // const [opening_hour, setOpeningHour] = useState("")
  // const [closing_hour, setClosingHour] = useState("")
  const handlePlaceSelect = (data) => {
    setAddress(data.formatted_address);
  };

  const [user, setUser] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormEnabled) return;
    setIsFormEnabled(false);
    sendPutFormData("/user-businesses", {
      first_name: user.first_name,
      last_name: user.last_name,
      business_name: user?.business?.business_name,
      business_logo: user?.business?.business_logo,
      business_type: user?.business?.business_type,
      business_description: user?.business?.business_description,
      business_logo: business_logo,
      opening_hour: user?.business?.opening_hour,
      closing_hour: user?.business?.closing_hour,
      country: user.country,
      business_identification: user?.business?.business_identification,
      address: address,
    })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setIsFormEnabled(true));
  };

  useEffect(() => {
    getCurrentLoggedInUser()
      .then((response) => {
        console.log(response.data.data);
        setUser(response.data.data);
        setAddress(response.data.data.address);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        setIsFormEnabled(true);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  return (
    <div className="panel settings">
      <h1 className="title">Settings</h1>
      <hr />
      <form onSubmit={handleSubmit} style={{ opacity: isFormEnabled ? 1 : 0.3 }}>
        <div>
          <label htmlFor="fname">
            First name <sup>*</sup>
          </label>{" "}
          <br />
          <input
            id="fname"
            type="text"
            placeholder="Your first legal name"
            value={user.first_name}
            onChange={(e) => {
              setUser({ ...user, first_name: e.target.value });
            }}
          />
        </div>
        <div>
          <label htmlFor="lname">
            Last name <sup>*</sup>
          </label>{" "}
          <br />
          <input
            id="lname"
            type="text"
            placeholder="Your last legal name"
            value={user.last_name}
            onChange={(e) => {
              setUser({ ...user, last_name: e.target.value });
            }}
          />
        </div>
        <div>
          <label htmlFor="bname">
            Business name <sup>*</sup>{" "}
            <span className="small">
              (Business Name is your Digitale Username)
            </span>{" "}
          </label>{" "}
          <br />
          <input
            id="bname"
            type="text"
            placeholder="Your business name"
            value={user?.business?.business_name}
            onChange={(e) => {
              setUser({
                ...user,
                business: { ...user?.business, business_name: e.target.value },
              });
            }}
          />
        </div>
        <div>
          <label htmlFor="bdname">
            Business Description <sup>*</sup>{" "}
          </label>{" "}
          <br />
          <textarea
            id="bdname"
            placeholder="Tell us more about your business."
            value={user?.business?.business_description}
            onChange={(e) =>
              setUser({
                ...user,
                business: {
                  ...user?.business,
                  business_description: e.target.value,
                },
              })
            }
            rows={5}
          ></textarea>
        </div>
        <div>
          <label htmlFor="bhour">
            Business Hours <sup>*</sup>
          </label>{" "}
          <br />
          {/* <input
            id="bhour"
            type="text"
            placeholder="Your business hours"
            {...register("businessHour")}
          /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="timepicker-container">
              <TimePicker
                label="Opening"
                // defaultValue={moment("2022-02-02 " + user?.business?.opening_hour).toDate()}
                value={user?.business?.opening_hour ? new Date(moment("2022-02-02 " + user?.business?.opening_hour, "YYYY-MM-DD hh:mm a").toString()): null}
                slotProps={{
                  textField: { size: "small", required: true },
                }}
                onChange={(e) => {
                  console.log(moment(e).format("h:mm a"));
                  setUser({
                    ...user,
                    business: {
                      ...user?.business,
                      opening_hour: moment(e).format("h:mm a"),
                    },
                  });
                }}
              />
              <span>-</span>
              <TimePicker
                label="Closing"
                slotProps={{ textField: { size: "small", required: true } }}
                value={user?.business?.closing_hour ? new Date(moment("2022-02-02 " + user?.business?.closing_hour, "YYYY-MM-DD hh:mm a").toString()) : null}
                onChange={(e) => {
                  console.log(moment(e).format("h:mm a"));
                  setUser({
                    ...user,
                    business: {
                      ...user?.business,
                      closing_hour: moment(e).format("hh:mm a"),
                    },
                  });
                }}
              />
            </div>
          </LocalizationProvider>
        </div>
        <div>
          <label htmlFor="bname">
            Upload Business Logo <sup>*</sup>
          </label>{" "}
          <br />
          <input
            id="bname"
            type="file"
            placeholder="Your business name"
            onChange={(e) => setBusinessLogo(e.target.files[0])}
          />
        </div>
        <div>
          <label htmlFor="bcat">Business type </label> <br />
          <select
            name="bcat"
            id="bcat"
            value={user?.business?.business_type}
            onChange={(e) =>
              setUser({
                ...user,
                business: {
                  ...user?.business,
                  business_type: e.target.value,
                },
              })
            }
          >
            {/* <option value="type">Type</option> */}
            <option value="resturant">Restaurant</option>
            {/* <option value="grocery">Grocery Store</option>
            <option value="jewelry">Jewelry</option> */}
          </select>
        </div>
        <div>
          <label htmlFor="email">
            Business Email <sup>*</sup>
          </label>{" "}
          <br />
          <div className="with-icon">
            <FaEnvelope className="icon" />
            <input
              id="email"
              disabled
              type="email"
              placeholder="example@company.com"
              value={user?.email}
            />
          </div>
        </div>
        <div>
          <label htmlFor="country">
            Country <sup>*</sup>
          </label>{" "}
          <br />
          <select
            name="country"
            id="country"
            placeholder="Country"
            value={user?.country}
            onChange={(e) =>
              setUser({
                ...user,
                country: e.target.value,
              })
            }
          >
            <option value="country">Country</option>
            <option value="colombia">Colombia</option>
          </select>
        </div>
        <div>
          <label htmlFor="bday">Business Identification #</label> <br />
          <input
            id="bday"
            type="text"
            placeholder="Insert Rut/NIT #"
            value={user?.business?.business_identification}
            onChange={(e) =>
              setUser({
                ...user,
                business: {
                  ...user?.business,
                  business_identification: e.target.value,
                },
              })
            }
          />
        </div>
        <div>
          <label htmlFor="address">
            Address <sup>*</sup>
          </label>{" "}
          <br />
          <GooglePlacesAutocomplete
            defaultValue={user.address}
            apiKey={apiKey}
            onChange={(e) => setAddress(e.target.value)}
            onPlaceSelected={handlePlaceSelect}
            options={{
              types: "city",
            }}
          />
          {/* <input
            type="text"
            id="address"
            placeholder="Enter address..."
            value={user?.address}
            onChange={(e) =>
              setUser({
                ...user,
                address: e.target.value,
              })
            }
            required
          /> */}
        </div>
        <div className="text-check">
          <input
            className="check"
            type="checkbox"
            // checked={check}
            onChange={handleCheck}
            required
          />
          <span>
            I Agree to
            <a
              target="_blank"
              href="https://www.docdroid.net/rawVjvg/cryptoshare-terms-and-conditions-pdf"
            >
              {" "}
              Terms & Conditions,
            </a>
            <a
              target="_blank"
              href="https://www.docdroid.net/5gwsBLX/anti-money-laundering-cryptosharepay-1-1-pdf"
            >
              AML,
            </a>
            and{" "}
            <a
              target="_blank"
              href="https://www.docdroid.net/astXB3F/cryptoshare-privacy-policy-pdf"
            >
              Jurisdiction Policy
            </a>
            .
          </span>
        </div>
        <div>
          <button className="btn-set" type="submit">
            Done
          </button>
        </div>
      </form>
    </div>
  );
};

export default Setting;
