import { Box, Modal } from "@mui/material";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import { useState } from "react";

const StatusChangeModal = ({ order, openStatusModal, setOpenStatusModal, orders, setOrders }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [formEnabled, setFormEnabled] = useState(true)
  const { sendPostFormData } = useApi()
  const updateOrderStatus = (status) => {
    if (!formEnabled)
      return;
    setFormEnabled(false)
    sendPostFormData("/orders/" + order.id + "/change-status", {status: status}).then(response => {
        var new_orders = orders.map((single_order) => {
            if (single_order.id == order.id)
                single_order.order_status = status
            return single_order
        })
        setOrders(new_orders)
        setOpenStatusModal(false)
        toast.success("Order status changed")
    }).catch(err => {
        toast.error(err.message)
    }).finally(() => {
      setFormEnabled(true)
    })
  }
  const handleClose = () => setOpenStatusModal(false);
  console.log(openStatusModal);
  return (
    <Modal
      open={openStatusModal}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400, opacity: formEnabled ? "1" : "0.3" }}>
        {/* <h2 id="parent-modal-title">Text in a modal</h2>
      <p id="parent-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p> */}
        <div style={{ display: "flex", columnGap: "10px" }}>
          <button className="status-preparing" onClick={() => updateOrderStatus("preparing")}>Preparing</button>
          <button className="status-delivering" onClick={() => updateOrderStatus("delivering")}>Delivering</button>
          <button className="status-delivered" onClick={() => updateOrderStatus("delivered")}>Delivered</button>
          <button className="status-cancelled" onClick={() => updateOrderStatus("cancelled")}>Cancelled</button>
        </div>
      </Box>
    </Modal>
  );
};

export default StatusChangeModal;
