import React, { useEffect, useState } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import "./Resturant.css";
import useApi from "../../../hooks/useApi";
import { useSelector } from "react-redux";
import CustomerLayout from "../../Layout/CustomerLayout";

const Resturant = () => {
  const limitTextDisplay = (str, length) => {
    if (str.length <= length) return str;
    return str.substring(0, length - 3) + "...";
  };
  const { sendGet } = useApi();
  const [businesses, setBusinesses] = useState([]);
  useEffect(() => {
    sendGet("/businesses-public")
      .then((response) => {
        console.log("business: ", response.data.data);
        response.data.data.forEach((item) => {
          setBusinesses((businesses) => [...businesses, item]);
        });
      })
      .catch((err) => {
        console.log("business get error: ", err.message);
      });
  }, []);
  return (
    <CustomerLayout>
      {businesses.map((business) => (
        <>
          <div className="logo-body">
            <div className="logo-rest">
              <img src={business.logo_url} alt="" />
            </div>
          </div>
          <div className="logo-ref">{business.business_description}</div>
          <br/>
          <div className="logo-ref">
          <span>{business?.user?.address}</span>
          <br/>
          <br/>
            <span>
              (Business Hours: {business.opening_hour} - {business.closing_hour}{" "})
            </span>
          </div>
          <div className="product-list">
            <div className="slide-container">
              {business.products?.map((product) => (
                <div className="card-new" key={product.id}>
                  <div className="card-img">
                    <img src={product.image_url} alt="" />
                    <div className="icon-prod">
                      <BsFillHeartFill className="heart-icon" />
                      <div className="heart-count">1</div>
                      <FaShareAlt className="share" />
                    </div>
                  </div>
                  <div className="card-det">
                    <div>
                      <span>Product Category:</span>
                      <span>{product.category}</span>
                    </div>
                    <div>
                      <span>Product Name:</span>
                      <span>{limitTextDisplay(product.name, 28)}</span>
                    </div>
                    {/* <div>
                      <span>Product Description:</span>
                      <span>{product.description}</span>
                    </div> */}
                    <div>
                      <span>Price:</span>
                      <span>{product.price} COP$</span>
                    </div>
                    <div>
                      <span>Order Method:</span>
                      <span>{product.order_method}</span>
                    </div>
                    {/* <span><strong>Product Category:</strong> Sides</span> <br />
                          <span className='blu'>Salad</span> <br />
                          <span><strong>Product:</strong><span className='under-salad'>A Vegetable Salad</span></span><br /><br /> 
                          <span className="head-card"><strong>Price:</strong></span>
                          <span>50.000 COP$</span>
                          <span>=</span>
                          <span>17.07 XRP</span> <br />
                          <span><strong>Order Method:</strong> Delivery & Pickup</span>  */}
                    <Link to={"/checkout/" + product.slug} target="_blank">
                      <button className="btn-lcard">Preview</button>
                    </Link>
                  </div>
                </div>
              ))}

              {/* First Slide */}
              {/* <div className="respro">
  
                          <div className="pro-1">
                              <div className="icon-prod">
                                  <BsFillHeartFill className='heart-icon' />
                                  <div className="heart-count">1</div>
                                  <FaShareAlt className="share" />
                              </div>
                              <div className="image-pro">
                                  <img src={salad} alt="" />
                              </div>
                              <div className="details-pro">
                                  <div className="cat-pro">
                                      <h4>Product Category:</h4>
                                      <span>Sides</span>
                                  </div>
                                  <span className='classic'>Salad</span>
                                  <div className="pro-pro">
                                      <h4>Product:</h4>
                                      <span>A Vegetable Salad</span>
                                  </div>
                                  <div className="price-pro">
                                      <h4>Price:</h4>
                                      <span>50.000 COP$</span>
                                      <span>=</span>
                                      <span>17.07 XRP</span>
                                  </div>
                                  <div className="method">
                                      <h4>Order Method:</h4>
                                      <span>Delivery & Pickup</span>
                                  </div>
                              </div>
                          </div>
                          <div className="btn-pro">
                              <Link to='/checkout'><button>Preview</button></Link>
                          </div>
  
                      </div> */}

              {/* Second Slide */}
              {/* <div className="respro">
  
                          <div className="pro-1">
                              <div className="icon-prod">
                                  <BsFillHeartFill className='heart-icon' />
                                  <div className="heart-count">1</div>
                                  <FaShareAlt className="share" />
                              </div>
                              <div className="image-pro">
                                  <img src={food} alt="" />
                              </div>
                              <div className="details-pro">
                                  <div className="cat-pro">
                                      <h4>Product Category:</h4>
                                      <span>Entree</span>
                                  </div>
                                  <span className='classic'>Shrimp Pasta</span>
                                  <div className="pro-pro">
                                      <h4>Product:</h4>
                                      <span>A Linguini Pasta with Shrimp <br /> and Vegetables</span>
                                  </div>
                                  <div className="price-pro">
                                      <h4>Price:</h4>
                                      <span>70.000 COP$</span>
                                      <span>=</span>
                                      <span>22.35 XRP</span>
                                  </div>
                                  <div className="method">
                                      <h4>Order Method:</h4>
                                      <span> Pickup</span>
                                  </div>
                              </div>
                          </div>
                          <div className="btn-pro">
                              <Link to='/checkouts'><button>Preview</button></Link>
                          </div>
  
                      </div> */}
            </div>
          </div>
        </>
      ))}
    </CustomerLayout>
  );
};

export default Resturant;
