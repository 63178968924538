import React from "react";
import { useEffect } from "react";
import "./Product.css";
import { useState } from "react";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { Popup } from "../../../Example";
import axios from "axios";
import { useForm } from "react-hook-form";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import useBusinessSignup from "../../../hooks/useBusinessSignup";
import { Link } from "react-router-dom";
import DeleteDialog from "./DeleteDialog";

const Product = () => {
  const serviceCharge = 5000;
  const [formEnabled, setFormEnabled] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [productToEdit, setProductToEdit] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteProduct, setDeleteProduct] = useState({})
  const { getCurrentBusiness } = useBusinessSignup();
  const [business, setBusiness] = useState({});
  useState(() => {
    var businessPromise = getCurrentBusiness();
    businessPromise
      .then((response) => {
        console.log(response.data.data);
        setBusiness(response.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  const [select, setSelect] = useState("cop");
  const [pricePro, setPricePro] = useState();

  const [isXrp, setIsXrp] = useState(false);
  const [isBtc, setIsBtc] = useState(false);
  const [isEth, setIsEth] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopup = (product) => {
    document.getElementById("popup-" + product.id).style.display = "block";
  };

  const onEditClick = (product) => {
    console.log("editclieck", product);
    setProductToEdit(product);
    setIsUpdate(true);
  };

  const onDeleteClick = (product) => {
    console.log("delete click", product);
    setDeleteProduct(product)
    setDeleteOpen(true)
  };

  const onCancelClick = () => {
    setProductToEdit({
      name: "",
      description: "",
      price: 0,
      original_price: 0,
      order_method: "Delivery",
      category: "Normal Drinks",
    });
    setIsUpdate(false);
  };

  const publishSingleProduct = (product) => {
    sendPutFormData("/products/" + product.slug + "/publish", {
      status: product.is_published ? 0 : 1,
    })
      .then((response) => {
        toast.success(response.data.message);
        setProducts(
          products.map((item) => {
            if (item.id == product.id) return response.data.data;
            return item;
          })
        );
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const publishAllProduct = (product) => {
    sendPutFormData("/products/" + business.id + "/publish-all", {
      status: 1
    })
      .then((response) => {
        toast.success(response.data.message);
        setProducts(response.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const [products, setProducts] = useState([]);
  const { sendGet, sendPostFormData, sendPutFormData } = useApi();
  const { register, handleSubmit } = useForm();
  const [amount, setAmount] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("SELECT");
  const [converted, setConverted] = useState("");
  const [convertedBtc, setConvertedBtc] = useState("");
  const [convertedXrp, setConvertedXrp] = useState("");
  const [exchangeRates, setExchangeRates] = useState({
    BTC: 0,
    ETH: 0,
    XRP: 0,
  });
  const one_usd_in_cop = 4017.44;
  useEffect(() => {
    getProducts();
    const fetchBitcoinPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=btc,eth,xrp"
        );
        const rates = response.data.usd;
        console.log(response.data);
        setExchangeRates({
          BTC: rates.btc,
          ETH: rates.eth,
          XRP: rates.xrp,
        });
        // setConvertedBtc(priceData);
      } catch (error) {
        console.error("Error fetching Bitcoin price:", error);
      }
    };
    onCancelClick();
    fetchBitcoinPrice();
  }, []);
  const getProducts = () => {
    sendGet("/products")
      .then((response) => {
        console.log("products: ", response.data.data);
        response.data.data.forEach((item) => {
          setProducts((products) => [...products, item]);
        });
      })
      .catch((err) => {
        console.log("products get err: ", err.message);
      });
  };
  const removeProductFromList = (product_id) => {
    setProducts(products.filter(item => {
      return item.id != product_id
    }) )
  }
  const handleCurrencyChangeXrp = (currencyXrp) => {
    setSelectedCurrency(currencyXrp);
    // Calculate the converted price based on the selected currency
    const convertedValueXrp = amount / exchangeRates.XRP;
    setConvertedXrp(convertedValueXrp);
  };
  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    // Calculate the converted price based on the selected currency
    const convertedValue = amount / exchangeRates.ETH;
    setConverted(convertedValue);
  };
  const handleCurrencyChangeBtc = (currencyBtc) => {
    setSelectedCurrency(currencyBtc);
    // Calculate the converted price based on the selected currency
    const convertedValueBtc = amount / exchangeRates.BTC;
    setConvertedBtc(convertedValueBtc);
  };

  const handleCheckboxXrp = () => {
    setIsXrp(!isXrp);
  };
  const handleCheckboxBtc = () => {
    setIsBtc(!isBtc);
  };
  const handleCheckboxEth = () => {
    setIsEth(!isEth);
  };
  const onSubmit = (data) => {
    if (!formEnabled) return;
    console.log(data);
    setFormEnabled(false);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("order_method", data.order_method); // Assumes a single file upload
    formData.append("image", data.image[0]);
    formData.append("category", data.category);

    if (isUpdate) {
      console.log(productToEdit.image);
      sendPostFormData(
        "/products/" + productToEdit.slug,
        {
          name: productToEdit.name,
          description: productToEdit.description,
          price: productToEdit.original_price,
          order_method: productToEdit.order_method,
          category: productToEdit.category,
          image: data.image[0],
          _method: "PUT",
        },
        {
          isAuth: true,
        }
      )
        .then((response) => {
          toast.success(response.data.message);
          setProducts((products) =>
            products.map((item) => {
              if (item.id == response.data.data.id) item = response.data.data;
              return item;
            })
          );
          onCancelClick();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setFormEnabled(true);
        });
    } else {
      sendPostFormData("/products", formData, { isAuth: true })
        .then((response) => {
          toast.success(response.data.message);
          setProducts((products) => [...products, response.data.data]);
          onCancelClick();
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setFormEnabled(true);
        });
    }
  };
  return (
    <>
    <DeleteDialog open={deleteOpen} setOpen={setDeleteOpen} product={deleteProduct} removeProductFromList={removeProductFromList} />
    <div className="panel product-pannel">
      <div className="flex-head">
        <h1 className="title">Create Products</h1>
        <div>
          <select>
            <option value="s1">Restaurant</option>
            <option value="s2" disabled>
              Grocery Store
            </option>
            <option value="s3" disabled>
              Jewelry Store
            </option>
          </select>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ opacity: formEnabled ? 1 : 0.3 }}
      >
        <div className="form-flex">
          <div>
            <div>
              <label htmlFor="name">
                Product Name <sup>*</sup>
              </label>{" "}
              <br />
              <input
                id="name"
                {...register("name")}
                type="text"
                placeholder="Add product name"
                maxLength="45"
                value={productToEdit.name}
                onChange={(e) =>
                  setProductToEdit({ ...productToEdit, name: e.target.value })
                }
                required
              />
            </div>
            <div>
              <label htmlFor="desc">
                Product Description <sup>*</sup>
              </label>{" "}
              <br />
              <textarea
                id="desc"
                {...register("description")}
                cols="60"
                rows="5"
                placeholder="Enter your product description..."
                value={productToEdit.description}
                onChange={(e) =>
                  setProductToEdit({
                    ...productToEdit,
                    description: e.target.value,
                  })
                }
                maxLength="100"
                required
              ></textarea>
            </div>
            <div style={{margin: "10px 0"}}>
              <label htmlFor="price">
                Product Price COP$<sup>*</sup>
              </label>{" "}
              <br/>
              <label style={{color: "red"}}>({serviceCharge} COP will be added as service charge)</label>
              <br />
              <input
                id="price"
                type="number"
                {...register("price")}
                placeholder="Enter you product price"
                // value={amount}
                value={productToEdit.original_price}
                onChange={(e) => {
                  setProductToEdit({ ...productToEdit, original_price: e.target.value });
                }}
                required
              />
              <br/>
              <label>After service charge: {productToEdit.original_price ? (+productToEdit.original_price + serviceCharge) : 0} </label>
            </div>
            <div>
              <label htmlFor="currency">
                Choose Currencies your Company will Accept <sup>*</sup>
              </label>

              <div className="currencySelect" required>
                <div className="singleC">
                  <label htmlFor="cop">COP$</label>{" "}
                  <input
                    type="checkbox"
                    id="cop"
                    value="cop"
                    checked="cop"
                    onChange={(e) => setSelect(e.target.value)}
                  />{" "}
                </div>
                {business.bitcoin_address && (
                  <div className="singleC">
                    <label htmlFor="btc">BITCOIN</label>{" "}
                    <input
                      type="checkbox"
                      id="btc"
                      name="Btc"
                      checked={isBtc}
                      value="BTC"
                      onClick={(e) => {
                        handleCurrencyChangeBtc(e.target.value);
                      }}
                      onChange={handleCheckboxBtc}
                    />{" "}
                  </div>
                )}
                {business.ethereum_address && (
                  <div className="singleC">
                    <label htmlFor="eth">ETH</label>{" "}
                    <input
                      type="checkbox"
                      id="eth"
                      name="Eth"
                      checked={isEth}
                      value="ETH"
                      onClick={(e) => {
                        handleCurrencyChange(e.target.value);
                      }}
                      onChange={handleCheckboxEth}
                    />{" "}
                  </div>
                )}
                {business.xrp_address && (
                  <div className="singleC">
                    <label htmlFor="xrp">XRP</label>{" "}
                    <input
                      type="checkbox"
                      id="xrp"
                      name="Xrp"
                      checked={isXrp}
                      value="XRP"
                      onClick={(e) => {
                        handleCurrencyChangeXrp(e.target.value);
                      }}
                      onChange={handleCheckboxXrp}
                    />{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="delivery">
                Order Method <sup>*</sup>
              </label>
              <select
                {...register("order_method")}
                value={productToEdit.order_method}
                onChange={(e) =>
                  setProductToEdit({
                    ...productToEdit,
                    order_method: e.target.value,
                  })
                }
                required
              >
                <option value="Delivery">Delivery</option>
                <option value="Pickup">Pickup</option>
                <option value="Both">Both</option>
              </select>
            </div>
            <div>
              <label htmlFor="pic">
                Upload Pictures of Product <sup>*</sup>
              </label>{" "}
              <br />
              <input
                type="file"
                {...register("image")}
                onChange={(e) =>
                  setProductToEdit({ ...productToEdit, image: e.target.value })
                }
              />
              {/* {selectedCurrency !== 'SELECT' && (<p>Converted Price in {selectedCurrency}: {converted}</p>)} */}
            </div>
            <div>
              <label htmlFor="category">
                Product Category <sup>*</sup>
              </label>{" "}
              <br />
              <select
                {...register("category")}
                value={productToEdit.category}
                onChange={(e) =>
                  setProductToEdit({
                    ...productToEdit,
                    category: e.target.value,
                  })
                }
              >
                <option value="Normal Drinks">Normal Drinks</option>
                <option value="Alcohol Drinks">Alcohol Drinks</option>
                <option value="Desserts">Desserts</option>
                <option value="Sides">Sides</option>
                <option value="Entrees">Entrees</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          {isBtc && (
            <div className="coversion">
              <label htmlFor="con">Conversion</label>
              <div className="con-inputs">
                <div className="COP">
                  <input
                    type="number"
                    value={pricePro || productToEdit.price}
                    // onChange={(e) => handlePriceChangeBtc(e.target.value)}
                    disabled
                  />
                  <label htmlFor="">COP$</label>
                </div>
                <div className="con-icon">
                  <CgArrowsExchangeAlt className="con-change" />
                </div>
                <div className="converted-amount">
                  {
                    <span>
                      {parseFloat(
                        (exchangeRates.BTC * productToEdit.price) /
                          one_usd_in_cop
                      ).toFixed(20) || 0}
                    </span>
                  }
                </div>
                <div className="other-cons">
                  <select
                    value={amount}
                    // onChange={(e) => handleCurrencyChangeBtc(e.target.value)}
                  >
                    <option value="BTC">BITCOIN</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {isEth && (
            <div className="coversion">
              <label htmlFor="con">Conversion</label>
              <div className="con-inputs">
                <div className="COP">
                  <input
                    type="number"
                    value={pricePro || amount}
                    // onChange={(e) => handlePriceChange(e.target.value)}
                    disabled
                  />

                  <label htmlFor="">COP$</label>
                </div>
                <div className="con-icon">
                  <CgArrowsExchangeAlt className="con-change" />
                </div>
                <div className="converted-amount">
                  {
                    <span>
                      {parseFloat(
                        (exchangeRates.ETH * amount) / one_usd_in_cop
                      ).toFixed(20) || 0}
                    </span>
                  }
                </div>
                <div className="other-cons">
                  <select
                    value={selectedCurrency}
                    onChange={(e) => handleCurrencyChange(e.target.value)}
                  >
                    {<option value="ETH">ETH</option>}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {isXrp && (
            <div className="coversion">
              <label htmlFor="con">Conversion</label>
              <div className="con-inputs">
                <div className="COP">
                  <input
                    type="number"
                    value={pricePro || amount}
                    // onChange={(e) => handlePriceChangeXrp(e.target.value)}
                    disabled
                  />

                  <label htmlFor="">COP$</label>
                </div>
                <div className="con-icon">
                  <CgArrowsExchangeAlt className="con-change" />
                </div>
                <div className="converted-amount">
                  {
                    <span>
                      {parseFloat(
                        (exchangeRates.XRP * amount) / one_usd_in_cop
                      ).toFixed(20) || 0}
                    </span>
                  }
                </div>
                <div className="other-cons">
                  <select
                    value={selectedCurrency}
                    onChange={(e) => handleCurrencyChange(e.target.value)}
                  >
                    {<option value="XRP">XRP</option>}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
        <button className="pro-btn" type="submit">
          {isUpdate ? "Update" : "Create"}
        </button>
        {isUpdate && (
          <button
            type="button"
            className="pro-btn"
            style={{ marginLeft: "10px" }}
            onClick={onCancelClick}
          >
            Cancel
          </button>
        )}
      </form>
      <div className="listed-products">
        <div className="list-title">
          <div></div>
          <div className="listed-products-container">
            <h2>Listed Products</h2>
          </div>
          <div className="publish-all-container">
            <button onClick={publishAllProduct}>Publish All</button>
          </div>
        </div>

        <hr />
        <div className="products">
          <div className="examples">
            {products.map((product) => (
              <>
                <div className="products-pro" key={"product-pro-" + product.id}>
                  <div className="pro-1">
                    <div className="image-pro">
                      <img src={product.image_url} alt="" />
                    </div>
                    <div className="details-pro">
                      <div className="cat-pro">
                        <h4>Product Category:</h4>
                        <span>{product.category}</span>
                      </div>
                      <div className="pro-pro">
                        <h4>Product:</h4>
                        <span>{product.name}</span>
                      </div>
                      <div className="price-pro">
                        <h4>Price:</h4>
                        <span>{product.price} COP$</span>
                        {/* <span>=</span>
                      <span>17.07 XRP</span> */}
                      </div>
                      <div className="method">
                        <h4>Order Method:</h4>
                        <span>{product.order_method}</span>
                      </div>
                      <div className="method">
                        <h4>Status:</h4>
                        <span
                          className="classic"
                          style={{
                            background: product.is_published ? "" : "#E4A11B",
                          }}
                        >
                          {product.is_published ? "Published" : "Unpublished"}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="btn-pro">
                    <button
                      onClick={() => {
                        togglePopup(product);
                      }}
                    >
                      Preview
                    </button>
                    <button
                      onClick={() => publishSingleProduct(product)}
                      style={{
                        background: product.is_published ? "#E4A11B" : "",
                      }}
                    >
                      {product.is_published ? "Unpublish" : "Publish"}
                    </button>
                    <button onClick={() => onEditClick(product)}>Edit</button>
                    <button onClick={() => onDeleteClick(product)} style={{background: "#D4403A"}}>Delete</button>
                  </div>

                  {/* {isPopupVisible && <Popup product={product} closePopup= {() => {setIsPopupVisible(false)}} />} */}
                </div>
                {
                  <Popup
                    product={product}
                    closePopup={() => {
                      setIsPopupVisible(false);
                    }}
                  />
                }
              </>
            ))}

            {/* <div className="products-pro">
              <div className="pro-1">
                <div className="image-pro">
                  <img src={food} alt="" />
                </div>
                <div className="details-pro">
                  <div className="cat-pro">
                    <h4>Product Category:</h4>
                    <span>Entree</span>
                  </div>
                  <span className='classic'>Shrimp Pasta</span>
                  <div className="pro-pro">
                    <h4>Product:</h4>
                    <span>Linguini Pasta with Shrimp <br /> and Vegetables</span>
                  </div>
                  <div className="price-pro">
                    <h4>Price:</h4>
                    <span>70.000 COP$</span>
                    <span>=</span>
                    <span>22.35 XRP</span>
                  </div>
                  <div className="method">
                    <h4>Order Method:</h4>
                    <span> Pickup</span>
                  </div>
                </div>
              </div>
              <div className="btn-pro">
                <button onClick={togglePopups}>Preview</button>
                <Link to='/stores'><button className='publish-btn'>Publish</button></Link>
              </div>
            </div>
            {isPopupsVisible && <Popups />} */}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Product;
