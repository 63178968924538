import React, { useState } from "react";
// import "./Order.css";
import "./Table.css";
// import logo from "../assets2/KitchenLogo.png";
// import crypto from "../../assets/xrplogo.png";
import { Link } from "react-router-dom";
import { BsFillCircleFill } from "react-icons/bs";

function Account() {
  return (
    <div className="account-create">
      <Link to="/individual">
        {" "}
        <span>Get a Digitale Card</span>
      </Link>
    </div>
  );
}

function Pay() {
  const [user, setUser] = useState("");
  const handleInput = (e) => {
    setUser(e.target.value);
  };

  return (
    <div className="IDK">
      <div>
        <label>
          Digitale Username <sup>*</sup>
        </label>{" "}
        <br />
        <input
          type="text"
          placeholder="Enetr Digitale Username to pay with digital card"
          value={user}
          onChange={handleInput}
          required
        />
      </div>
      <div>
        <label>
          PIN CODE A <sup>*</sup>
        </label>
        <br />
        <input type="number" required /> <br />
        {user && (
          <span>
            <a href="/">(Request Pin Code A)</a>
          </span>
        )}
      </div>
      <div>
        <label>
          PIN CODE B <sup>*</sup>
        </label>
        <br />
        <input type="number" required /> <br />
        {user && (
          <span>
            <a href="/">(Request Pin Code B)</a>
          </span>
        )}
      </div>
      <div className="converted-prices">
        <label>Conversion:</label> <br />
        <span>Price in BTC = N/A</span>
        <br />
        <span>Price in ETH = N/A</span>
        <br />
        <span>Price in XRP = N/A</span>
      </div>
    </div>
  );
}

const Order = ({ price1, setPrice1 }) => {
  const [paymentOpt, setPaymentOpt] = useState("debit");
  const [delivery, setDelivery] = useState("A");
  const [customerId, setCustomerId] = useState("");
  const [choice, setChoice] = useState("enter");

  const handleChange = (e) => {
    setPaymentOpt(e.target.value);
  };
  function handleClick(e) {
    e.preventDefault();
  }
  const [tip, setTip] = useState(0);

  const handleTip = (e) => {
    setTip(e.target.value);
  };
  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
  };

  return (
    <>
      <div className="nav-links">
        <Link to="/login">
          <span>Home</span>
        </Link>
        <Link to="/restaurants">
          <span>Browse Restaurants</span>
        </Link>
      </div>
      <div className="large-container">
        <div className="Order">
          <div className="table">
            <div className="table-flex">
              <h3>Order#</h3>
            </div>
            <div className="table-flex">
              <h3>Product#</h3>
            </div>
            <div className="table-flex">
              <h3>Product Title</h3>
            </div>
            <div className="table-flex">
              <h3>Order ID</h3>
            </div>
            <div className="table-flex">
              <h3>Price COP$</h3>
            </div>
          </div>
          <div className="table-details">
            <div className="details-flex">
              <span>01</span>
              <span>02</span>
            </div>

            <div className="details-flex">
              <span>1</span>
              <span>2</span>
            </div>
            <div className="details-flex">
              <span>Vegetables & Salad</span>
              <span>Shrimp Pasta & Vegetables</span>
            </div>

            <div className="details-flex">
              <span>67698109</span>
              <span>67698159</span>
            </div>
            <div className="details-flex">
              <span>
                <BsFillCircleFill className="circle oranged" />
              </span>
              <span>
                <BsFillCircleFill className="circle" />
              </span>
            </div>
            <div className="details-flex">
              <span>
                man@pay <br /> digitale.com
              </span>
              <span>
                woman@pay <br /> digitale.com
              </span>
            </div>
            <div className="details-flex">
              <span>N/A</span>
              <span>Calle 8 #4-9 Tolu, Sucre</span>
            </div>
            <div className="details-flex">
              <span>XRP</span>
              <span>XRP</span>
            </div>
            <div className="details-flex">
              <span>50.000</span>
              <span>70.000</span>
            </div>
            <div className="details-flex">
              <span>ABC-123</span>
              <span>XYZ-987</span>
            </div>
          </div>
          <div className="customerChoices">
            <label htmlFor="choice" className="choices">
              <strong>Choose an option:</strong>
            </label>
            <br />
            <select id="choice" onChange={(e) => setChoice(e.target.value)}>
              <option value="enter">Enter Customer ID</option>
              <option value="create">Create Customer ID</option>
            </select>
          </div>
          {choice === "enter" ? (
            <>
              <label htmlFor="CustID" className="choices">
                Enter Customer ID: <sup>*</sup>
              </label>
              <br />
              <input
                type="text"
                id="CustID"
                value={customerId}
                onChange={handleCustomerIdChange}
                placeholder="Enter your Customer ID"
              />
              <div className="payment">
                <label>
                  Payment Method <sup>*</sup>
                </label>{" "}
                <br />
                <div>
                  <div>
                    <input
                      checked
                      type="radio"
                      value="debit"
                      onChange={handleChange}
                    />
                    <span>Debit/Credit Card</span>
                  </div>
                  <div className="sec-inp">
                    <input
                      type="radio"
                      value="bitcoin"
                      disabled
                      onChange={handleChange}
                    />
                    <span className="spanImg">
                      {" "}
                      <span>Pay with Bitcoin</span> ~ Coming Soon{" "}
                    </span>
                    <div className="account">
                      {paymentOpt === "bitcoin" && <Account />}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <form onSubmit={handleClick}>
              <div>
                <label>
                  Email <sup>*</sup>
                </label>{" "}
                <br />
                <input
                  type="email"
                  placeholder="Enter your Email here"
                  required
                />
              </div>
              {delivery === "A" && (
                <div>
                  <label>
                    Delivery Address <sup>*</sup>
                  </label>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder="Food will be delivered here"
                    required
                  />
                </div>
              )}
              <div>
                <label>
                  Special Instructions <sup>*</sup>
                </label>{" "}
                <br />
                <input
                  type="text"
                  placeholder="Receipt will be sent here..."
                  required
                />
              </div>
              <div>
                <label>
                  Phone # <sup>*</sup>
                </label>{" "}
                <br />
                <input type="number" required />
              </div>
              {/* <div className='quantity-order'>
            <label>Product Quantity <sup>*</sup></label> <br />
            <input type="number" required />
        </div> */}
              <div className="quantity-order">
                <label>
                  Date of Birth <sup>*</sup>
                </label>{" "}
                <br />
                <input type="date" required />
              </div>
              <div className="quantity-order">
                <label>Cedula# (optional)</label> <br />
                <input type="number" />
              </div>
              <div className="payment">
                <label>
                  Payment Method <sup>*</sup>
                </label>{" "}
                <br />
                <div>
                  <div>
                    <input
                      checked
                      type="radio"
                      value="debit"
                      onChange={handleChange}
                    />
                    <span>Debit/Credit Card</span>
                  </div>
                  <div className="sec-inp">
                    <input
                      type="radio"
                      value="bitcoin"
                      disabled
                      onChange={handleChange}
                    />
                    <span className="spanImg">
                      {" "}
                      <span>Pay with Bitcoin</span> ~ Coming Soon{" "}
                    </span>
                    <div className="account">
                      {paymentOpt === "bitcoin" && <Account />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="terms">
                <p>
                  {" "}
                  <input type="checkbox" /> I Agree to{" "}
                  <a href="https://docdro.id/rawVjvg">Terms & Conditions</a>,{" "}
                  <a href="https://www.docdroid.net/5gwsBLX/anti-money-laundering--1-1-pdf">
                    AML
                  </a>{" "}
                  & <a href="https://docdro.id/astXB3F">Privacy Policy</a>
                </p>
              </div>
            </form>
          )}
        </div>
        <div className="summary">
          {/* <div className="logo-image">
                        <div className="imageee"><img src={logo} alt="" /></div>
                    </div> */}
          <div>
            <label htmlFor="delivery">
              Order Method <sup>*</sup>
            </label>{" "}
            <br />
            <select
              value={delivery}
              onChange={(e) => {
                setDelivery(e.target.value);
              }}
            >
              <option value="A">Delivery</option>
              <option value="B">Pickup</option>
            </select>
          </div>
          <div className="order-summary">
            <h3>Summary</h3>
            <hr />
            <div className="summary-det">
              <div>
                <span>Product Category:</span>
                <span>Entree</span>
              </div>
              <div>
                <span>Product Name:</span>
                <span>Shrimp Pasta</span>
              </div>
              <div>
                <span>Product Description:</span>
                <span>A Linguini Pasta with Shrimp</span>
              </div>
              <div>
                <span>Price:</span>
                <span>70.000 COP$</span>
              </div>
            </div>
            <div className="order-spans">
              <span>Product Cost:</span>
              <span>0.000 COP$</span>
            </div>
            <div className="order-spans">
              <span>Service Cost:</span>
              <span>10.000 COP$</span>
            </div>
            <div className="order-spans">
              <span>Delivery Cost:</span>
              <span>10.000 COP$</span>
            </div>
            <div className="order-spans">
              <span>Add Tip:</span>
              <span>
                <input
                  type="number"
                  value={tip}
                  placeholder="0.000 COP$"
                  onChange={handleTip}
                />
              </span>
            </div>
            <div className="order-spans">
              <span className="total">TOTAL:</span>
              <span className="total">{tip + " COP$"}</span>
            </div>
          </div>

          <div className="order-btn">
            <button>Submit Order</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
