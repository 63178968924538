import React from 'react';
import "./IndDashboard.css";
import { AiFillDashboard, AiFillBuild } from "react-icons/ai";
import { FaWallet, FaStore } from "react-icons/fa";
import { BsSendFill, BsFillSendCheckFill } from "react-icons/bs"
import { MdAccountBalance, MdPayments } from "react-icons/md"
import { HiOutlineLogout } from "react-icons/hi"
import { IoMdContact, IoMdSettings } from "react-icons/io"
import { ImLocation } from 'react-icons/im'
import { TbBusinessplan } from "react-icons/tb";
import { useState, useEffect } from 'react';
import Wallet from '../Panels/Wallet/Wallet';
// import SendPayment from '../Panels/SendPayment/SendPayment';
// import RequestPay from '../Panels/RequestPayment/RequestPay';
// import Withdraw from '../Panels/Withdraw/Withdraw';
import flag1 from '../assets/usa.png'
import flag2 from '../assets/co.png'
import { Charts } from '../Panels/Bcredit/Chart';
import LastCredit from '../Panels/LastCredit/LastCredit';
import { Link } from 'react-router-dom';
import Profile from '../Panels/Profile/Profile'
import { SettingsInd } from '../Panels/Setting/Setting';
import logo from '../assets/digitalelogo.png'

const FlexItemTwo = () => {
  const [showComp, setShowComp] = useState(1);
  const [divVisible, setDivVisible] = useState(true);
  function handleClick(buttonId) {
    setShowComp(buttonId);
    setDivVisible(false);
  }
  return (
    <>
      {/* {showComp === 3 && <SendPayment />} */}
      {showComp === 2 && <Wallet />}
      {/* {showComp === 4 && <RequestPay />} */}
      {/* {showComp === 5 && <Withdraw />} */}
      {showComp === 6 && <Charts />}
      {showComp === 7 && <LastCredit />}
      {showComp === 8 && <SettingsInd />}
      {divVisible && <div>
        <div className="panel">
          <h2>Digitale Dashboard</h2>
          <hr />
          <div className="panel-list">
            <div className="panel-items">
              <FaWallet className='more-icons wallet' />
              <button onClick={() => handleClick(2)}>Wallet (Balance)</button>
            </div>
            <div className="panel-items">
              <TbBusinessplan className='more-icons credit' />
              <button onClick={() => handleClick(6)}>Orders</button>
            </div>

            <div className="panel-items">
              <BsFillSendCheckFill className='more-icons send' />
              <button onClick={() => handleClick(3)}>Send Digitale Payment</button>
            </div>
            <div className="panel-items">
              <MdPayments className='more-icons request' />
              <button onClick={() => handleClick(4)}>Request Digitale Payment</button>
            </div>
            <div className="panel-items">
              <MdAccountBalance className='more-icons withdraw' />
              <button onClick={() => handleClick(5)}>Withdraw Digitale Payment</button>
            </div>
            <div className="panel-items">
              <AiFillBuild className='more-icons withdraw' />
              <button onClick={() => handleClick(7)}>Build Credit</button>
            </div>
            <div className="panel-items">
              <IoMdSettings className='more-icons setting' />
              <button onClick={() => handleClick(8)}>Settings</button>
            </div>

          </div>
        </div>
      </div>}
    </>
  )
}

const IndDashboard = () => {

  const [showComp, setShowComp] = useState(1);
  const [showBtn, setShowBtn] = useState(false);
  const toggleButtons = () => {
    setShowBtn((prevShowBtn) => !prevShowBtn);
  };

  function handleClick(buttonId) {
    setShowComp(buttonId);
  }
  const [location, setLocation] = useState('');
  const [showPop, setShowPop] = useState(true);
  const handleLoc = (e) => {
    setLocation(e.target.value);
  }
  const locationClick = () => {
    if (location) {
      setShowPop(!showPop);
    }
  }


  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // Redirect the user after 2 minutes of inactivity
        window.location.href = '/redirect-page'; // Replace with your target URL
      }, 120000); // 2 minutes in milliseconds
    };

    const handleActivity = () => {
      resetTimer();
    };

    // Set up event listeners to reset the timer on user activity
    document.addEventListener('click', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('scroll', handleActivity);

    // Start the initial timer
    resetTimer();

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('click', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('scroll', handleActivity);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className='FullPage'>
      {showPop &&
        <>
          <div className="overlay"></div>
          <div className="location-popup">
            <div className="box-container">
              {/* <div className="centered">
                <h3>CREDIT GRADE: FFF</h3>
              </div> */}
              <form>
                <label htmlFor="loc">Address <sup>*</sup></label> <br />
                <input type="text" placeholder='Enter your Address to proceed.' value={location} onChange={handleLoc} required />
                <button onClick={locationClick}>Submit</button>
              </form>
            </div>
          </div>
        </>
      }
      <div className="main-board">
        <div className="item-1" onClick={() => handleClick(1)}>
          <AiFillDashboard className='main-icons dash' />
          <h3>Dashboard</h3>
        </div>

        <div className="item-mid item-ind">
          <h3>Digitale Individual Username: </h3>
        </div>
        <div className="item-2">
          <div className="profile">
            <div className="location-box">
              <ImLocation className='loc-pin' />
              {/* <input type="text" onChange={handleLoc}  /> */}
              <span>{location}</span>
            </div>
            <IoMdContact className='profile-icon' onClick={() => handleClick(9)} />



            <div className="lang-bar">
              <div><img src={flag1} alt="English" title='English' /></div>
              <div><img src={flag2} alt="Spanish" title='Spanish' /></div>
            </div>
          </div>

        </div>

      </div>
      <div className="Dashboard">
        <div className="Flex-1">
          <div className="navbar" onMouseEnter={toggleButtons} onMouseLeave={toggleButtons}>
            <div className="nav-icons ind" >

              <FaWallet className='panel-icons balance' />

              <TbBusinessplan className='panel-icons credit' />
              <BsSendFill className='panel-icons send' />
              <MdPayments className='panel-icons links' />
              <MdAccountBalance className='panel-icons withdraw' />
              <AiFillBuild className='panel-icons withdraw' />
              <FaStore className='panel-icons send' />
              <IoMdSettings className='panel-icons setting' />

              <HiOutlineLogout className='panel-icons out' />
            </div>
            <div className={`nav-buttons ind ${showBtn ? 'active' : ''}`}>
              <h3 className='abs-grade'>CREDIT GRADE: FFF</h3>
              <button onClick={() => { handleClick(2) }}>Wallet (Balance)</button>

              <button onClick={() => { handleClick(6) }}>Orders</button>
              <button onClick={() => { handleClick(3) }}>Send Digitale Payment</button>
              <button onClick={() => { handleClick(4) }}>Request Digitale Payment</button>
              <button onClick={() => { handleClick(5) }}>Withdraw Digitale Payment</button>
              <button onClick={() => { handleClick(7) }}>Build Credit</button>
              <Link to='/stores'><button>Browse Stores</button></Link>
              <button onClick={() => { handleClick(8) }}>Settings</button>

              <button><Link to='/' className='color-link'>Log Out</Link></button>

              <div className="panel-logo">
                <img src={logo} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="Flex-2">
          {/* <div className="panel">
            <h2>PANEL</h2>
            <hr />
            <div className="panel-list">
              <div className="panel-items">
                <FaWallet className='more-icons wallet' />
                <button>Wallet (Balance)</button>
              </div>
              <div className="panel-items">
                <MdPayments className='more-icons request' />
                <button>Request Payment</button>
              </div>
              <div className="panel-items">
                <BsFillSendCheckFill className='more-icons send' />
                <button>Send Payment</button>
              </div>
              <div className="panel-items">
                <MdAccountBalance className='more-icons withdraw' />
                <button>Withdraw</button>
              </div>
              <div className="panel-items">
                <TbBusinessplan className='more-icons credit' />
                <button>Build Credit</button>
              </div>
              <div className="panel-items">
                <AiTwotoneSetting className='more-icons setting' />
                <button>Settings</button>
              </div>
            </div>
          </div> */}
          {showComp === 1 && <FlexItemTwo />}
          {showComp === 2 && <Wallet />}
          {/* {showComp === 3 && <SendPayment />} */}
          {/* {showComp === 4 && <RequestPay />} */}
          {/* {showComp === 5 && <Withdraw />} */}
          {showComp === 6 && <Charts />}
          {showComp === 7 && <LastCredit />}
          {showComp === 8 && <SettingsInd />}
          {showComp === 9 && <Profile />}
        </div>
      </div>
    </div>
  )
}

export default IndDashboard