import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SimpleModal({open, setOpen}) {
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Info
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              After your payment, please check "My Orders" page for payment status.
              <br/>
              Unpaid orders will not be processed and will be removed after a while.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button style={{width: "100px"}} onClick={() => setOpen(false)}>Got It</button>
          </DialogActions>
        </Dialog>
      </>
    );
  }