import React, { useState } from 'react';
import './Individual.css';
import { FaEnvelope } from "react-icons/fa6";
import { BsFillTelephoneFill } from 'react-icons/bs'
import { Link } from 'react-router-dom';
import person1 from '../assets/person1.png';
// import person2 from '../assets/person2.png';
// import person3 from '../assets/person3.png';
import bg from '../assets/bg.png';
import { useForm } from 'react-hook-form';
import useIndividualSignup from '../../hooks/useIndividualSignup';
import { toast } from 'react-toastify';

const Individual = () => {
    const { register, handleSubmit } = useForm()

    const { inProcess, signup } = useIndividualSignup()

    const onSubmit = (data) => {
        console.log(data)
        if(data.cedula != data.cedula2){
            toast.error("Both Cedula Should be exactly Same. ")
            return;
        }

        if(!data.checkBox){
            toast.error("please accept terms and conditions");
            return;
        }

        if(!data.country){
            toast.error("please select a country");
            return;
        }
        signup(data.name,data.email, data.number, data.cedula, data.cedula, data.country, data.date);
    }

    return (

        <div className="business-form">
            <div className="person-1"><img src={person1} alt="" /></div>
            {/* <div className="person-2"><img src={person2} alt="" /></div>
            <div className="person-3"><img src={person3} alt="" /></div> */}
            <div className="bg"><img src={bg} alt="" /></div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='Main-title'>
                    <h1>Digitale - Sign Up</h1>
                    <p>Please type your credentials</p>
                </div>
                <div>
                    <label htmlFor="fullname">Full legal name <sup>*</sup></label> <br />
                    <input id='fullname' type="text" placeholder='Your full legal name' {...register("name")} required/>
                </div>

                <div>
                    <label htmlFor="email">Email<sup>*</sup></label> <br />
                    <div className="with-icon">
                        <FaEnvelope className='icon' />
                        <input type="email" placeholder='example@company.com' {...register("email")} required/>
                    </div>
                    <span className='FieldError'>(Email Address is your Digitale Username)</span>
                </div>
                <div>
                    <label htmlFor="email">Phone#<sup>*</sup></label> <br />
                    <div className="with-icon">
                        <BsFillTelephoneFill className='icon' />
                        <input type="number" placeholder='+00 000 00000' {...register("number")} required/>
                    </div>
                    <span className='FieldError'>(If you leave Blank, Pin Codes will be sent to Email Only)</span>
                </div>
                <div>
                    <label htmlFor="cedula">Cedula #</label> <br />
                    <input id='cedula' type="text" placeholder='Your identity document number' {...register("cedula")} required/>
                </div>
                <div>
                    <label htmlFor="cedula2">Confirm Cedula #</label> <br />
                    <input id='cedula2' type="text" placeholder='Confirm your identity document number' {...register("cedula2")} required/>
                </div>
                <div>
                    <label htmlFor="country">Country <sup>*</sup></label> <br />
                    <select name="country" id="country" {...register("country")}>
                        <option value="">Country</option>
                        <option value="colombia">Colombia</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="bday">Birthday <sup>*</sup></label> <br />
                    <input id='bday' type="date" {...register("date")} required />
                </div>
                <div>
                    <input className='check' type="checkbox" {...register("checkBox")} />
                    <span>I Agree to Terms & Conditions, AML, and Jurisdiction Policy.</span>
                </div>
                <div>
                    <button type='submit' disabled={inProcess}>{inProcess ? <span>Processing ...</span> : <span>Sign up</span>}</button>
                </div>
            </form>
        </div>
    )
}

export default Individual