import React, { useEffect, useState } from "react";
import "./MyOrder.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import MyOrdersTable from "./MyOrdersTable";
import CustomerLayout from "../Layout/CustomerLayout";

const MyOrders = () => {
  const cart = useSelector((state) => state.cart);
  const [selectedStatus, setSelectedStatus] = useState("")
  const { sendGet } = useApi();
  const [orders, setOrders] = useState([]);
  console.log("selected status: ", selectedStatus)
  useEffect(() => {
    getOrders()
  }, []);
  useEffect(() => {
    getOrders()
  }, [selectedStatus])
  const getOrders = () => {
    sendGet("/orders?status=" + selectedStatus)
      .then((response) => {
        setOrders(response.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  return (
    <>
      {/* <div className="nav-links">
        <Link to="/login">
          <span>Home</span>
        </Link>
        <Link to="/restaurants">
          <span>Browse Restaurants</span>
        </Link>
        
      </div> */}
      <CustomerLayout>
      <div className="large-container">
        <div className="Order">
          <div className="header-container">
            <h1>My Orders</h1>
            <select className="status-selector" onChange={e => setSelectedStatus(e.target.value)}>
              <option value="">All</option>
              <option value="preparing">Preparing</option>
              <option value="delivering">Delivering</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
          <MyOrdersTable orders={orders} />
        </div>
      </div>
      </CustomerLayout>
    </>
  );
};

export default MyOrders;
