import React from 'react'
import './LastCredit.css'
// import  credit  from '../assets2/CreditGrades.png'

const LastCredit = () => {
    return (
        <div className="panel LastCredit">
            <div className='subcredit'>
                <h1 className='title'>What is Digitale Credit?</h1>
                <hr />
                <ul>
                    <li>Digitale Credit is a new way to Borrow & Lend. It is a Decentralized Credit System that allows you to get Products & Services using Credit instead of your Digitale.</li>
                    <li>Your Credit Grade is based on your Digitale Balance.  Once you initiate a loan the Digitale amount (plus Interest) is held in a Smart Contract until you pay back the loan with Interest.</li>
                    <li>All Digitale Loans will be in USDC.</li>
                    <li>If you default your Digitale (plus interest) will automatically be released by the Smart Contract to the Lender.</li>
                </ul>
            </div>
            <div className="credit-img">
                {/* <img src={credit}  alt='credit'/> */}
            </div>

        </div>
    )
}

export default LastCredit