import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";
import MyOrdersTable from "./OrderTable";

function Account() {
  return (
    <div className="account-create">
      <Link to="/individual">
        {" "}
        <span>Get a Digital Card</span>
      </Link>
    </div>
  );
}

function Pay() {
  const [user, setUser] = useState("");
  const handleInput = (e) => {
    setUser(e.target.value);
  };

  return (
    <div className="IDK">
      <div>
        <label>
          Digitale Username <sup>*</sup>
        </label>{" "}
        <br />
        <input
          type="text"
          placeholder="Enetr Digitale Username to pay with Digital Card"
          value={user}
          onChange={handleInput}
          required
        />
      </div>
      <div>
        <label>
          PIN CODE A <sup>*</sup>
        </label>
        <br />
        <input type="number" required /> <br />
        {user && (
          <span>
            <a href="/">(Request Pin Code A)</a>
          </span>
        )}
      </div>
      <div>
        <label>
          PIN CODE B <sup>*</sup>
        </label>
        <br />
        <input type="number" required /> <br />
        {user && (
          <span>
            <a href="/">(Request Pin Code B)</a>
          </span>
        )}
      </div>
      <div className="converted-prices">
        <label>Conversion:</label> <br />
        <span>Price in BTC = N/A</span>
        <br />
        <span>Price in ETH = N/A</span>
        <br />
        <span>Price in XRP = N/A</span>
      </div>
    </div>
  );
}

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("")
  const { sendGet } = useApi();
  const [tip, setTip] = useState(0);

  const handleTip = (e) => {
    setTip(e.target.value);
  };
  console.log("selected status: ", selectedStatus)
  useEffect(() => {
    getOrders()
  }, []);
  useEffect(() => {
    getOrders()
  }, [selectedStatus])
  const getOrders = () => {
    sendGet("/orders?status=" + selectedStatus)
      .then((response) => {
        setOrders(response.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  return (
    <>
      <div className="nav-links">
        <Link to="/login">
          <span>Home</span>
        </Link>
        <Link to="/restaurants">
          <span>Browse Restaurants</span>
        </Link>
      </div>
      <div className="large-container">
        <div className="Order">
        <select className="status-selector" style={{float: "right", marginBottom: "10px"}} onChange={e => setSelectedStatus(e.target.value)}>
              <option value="">All</option>
              <option value="preparing">Preparing</option>
              <option value="delivering">Delivering</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
            </select>
        <MyOrdersTable orders={orders} setOrders={setOrders} />
        </div>
        
      </div>
    </>
  );
};

export default Order;
