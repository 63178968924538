const initial_state = JSON.parse(localStorage.getItem("cartItems")) || [];
export default function cartReducer(cart = initial_state, action) {
  console.log({ product });
  if (action.type == "ADD_CART") {
    console.log("add_cart");
    var product = action.payload?.product;
    if (!product) return cart;
    var product_already_exists = false;
    cart = cart
      ?.map((item) => {
        if (product.id == item.id) {
          product_already_exists = true;
          var quantity = item.quantity + action.payload.quantity;
          if (quantity <= 0) return false;
          item["quantity"] = quantity;
        }
        return item;
      })
      .filter((item) => item);
    console.log({ cart });
    if (!product_already_exists && action.payload.quantity > 0)
      cart = [...cart, { ...product, quantity: action.payload.quantity }];
    localStorage.setItem("cartItems", JSON.stringify(cart));
    return cart;
  } else if (action.type == "CLEAR_CART") {
    console.log("clear cart");
    localStorage.removeItem("cartItems");
    return [];
  } else return cart;
}
