import React, { useEffect, useState } from "react";
// import salad from "../assets/pro1.jpg";
import "./Checkout.css";
import { Link, useParams } from "react-router-dom";
import { BsFillHeartFill } from "react-icons/bs";
import { BiSolidHappyAlt } from "react-icons/bi";
import { FaShareAlt } from "react-icons/fa";
import { FaRegFaceLaugh, FaRegFaceMeh, FaRegFaceTired } from "react-icons/fa6";
import { AiOutlineShoppingCart } from "react-icons/ai";
import useApi from "../../../hooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../actions/cartAction";
import { checkIfProductIsAlreadyInCart } from "../../../hooks/useCart";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import SendMailModal from "./SendMailModal";
import ShareModal from "./ShareModal";
// import {AiOutlineCloseCircle} from 'react-icons/ai'

const Checkout = () => {
  const cartItems = useSelector((state) => state.cart);
  const [mailOpen, setMailOpen] = useState(false)
  const [customerRating, setCustomerRating] = useState({})
  const {currentUser} = useAuth()
  // console.log({ cartItems });
  const dispatch = useDispatch();
  const { product_slug } = useParams();
  const [product, setProduct] = useState({});
  // console.log(product_slug);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState(0);
  const [price1, setPrice1] = useState(50000);
  const { sendGet, sendPostFormData } = useApi();
  const [productAlreadyInCart, setProductAlreadyInCart] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const storeReview = (rating) => {
    sendPostFormData("/product-reviews", {
      customer_id: currentUser()?.id,
      product_id: product.id,
      rating
    }).then(response => {
      setCustomerRating(response.data.data)
      toast.success("Rating saved successfully")
    }).catch(err => {
      toast.error(err.message)
    })
  }
  const checkIfItemIsRestaurantCompatible = () => {
    if (cartItems.length == 0 )
      return true
    if (!cartItems[0].business?.id || !product?.business?.id)
      return false
    return cartItems[0].business.id == product.business.id
  }

  useEffect(() => {
    sendGet("/products/" + product_slug)
      .then((response) => {
        setProduct(response.data.data.product);
        setProductAlreadyInCart(
          checkIfProductIsAlreadyInCart(response.data.data.product.id)
        );
        setCustomerRating(response.data.data.customer_rating)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };
  const handleBoth = () => {
    if (!checkIfItemIsRestaurantCompatible())
      return toast.error("Cart can only have items from single restaurant")
    dispatch(addToCart(product, quantity));
    setProductAlreadyInCart(checkIfProductIsAlreadyInCart(product.id));
  };
  const setOrder = () => {
    setPrice1(quantity * price1);
  };
  return (
    <>
    <ShareModal open={shareModalOpen} setOpen={setShareModalOpen} />
    <SendMailModal open={mailOpen} setOpen={setMailOpen} business={product.business} />
    <div className="checkout">
      {/* <ul>
        <li>
          <h1>Lists</h1>
        </li>
        {cartItems.map((item) => (
          <li key={item.id}>
            {item.name}: {item.quantity}{" "}
          </li>
        ))}
      </ul> */}
      <div className="products-pro">
        <div className="order-container">
          <div className="image-pro">
            <img src={product?.image_url} alt="" />
          </div>
          <div className="details-pro">
            <div className="icon-check" onClick={(e) => setShareModalOpen(true)}>
              <BsFillHeartFill className="heart-icon-check" />
              <div className="heart-count-check">1</div>
              <FaShareAlt className="shared" />
            </div>
            <div className="check-det">
              <div>
                <span>Restaurant:</span>
                <span>{product?.business?.business_name}</span>
              </div>
              <div>
                <span>Product Category:</span>
                <span>{product?.category}</span>
              </div>
              <div>
                <span>Product Name:</span>
                <span>{product?.name}</span>
              </div>
              {/* <div>
                    <span>Product Description:</span>
                    <span>A Vegetable Salad</span>
                </div> */}
              <div>
                <span>Price:</span>
                <span>{product?.price} COP$</span>
              </div>
              <div>
                <span>Order Method:</span>
                <span>{product?.order_method}</span>
              </div>
              <div>
                <span>Address:</span>
                <span style={{width: "350px"}}>{product?.business?.user?.address}</span>
              </div>
            </div>
          </div>
          <div className="review">
            <div className="heading-review">How is your experience?</div>
            <div className="review-flex">
              <div onClick={() => storeReview(3)} className={customerRating?.rating == 3 ? "rating-selected" : ""}>
                <FaRegFaceLaugh className="face happy" />
                <span className="happy">Excellent</span>
              </div>
              <div onClick={() => storeReview(2)} className={customerRating?.rating == 2 ? "rating-selected" : ""}>
                <FaRegFaceMeh className="face okay" />
                <span className="okay">Okay</span>
              </div>
              <div onClick={() => storeReview(1)} className={customerRating?.rating == 1 ? "rating-selected" : ""}>
                <FaRegFaceTired className="face sad"/>
                <span className="sad">Not Good</span>
              </div>
            </div>
          </div>
        </div>
        <Link className="cart-icon" to="/my-cart">
          <div className="cart-circle">
            <span>{cartItems.length}</span>
          </div>
          <AiOutlineShoppingCart classNamecart-icon />
        </Link>
        <div className="quantity">
          <div className="counter">
            {!productAlreadyInCart && (
              <>
                <button className="quantity-button" onClick={handleDecrease}>
                  -
                </button>
                <span className="quantity">{quantity}</span>
                <button className="quantity-button" onClick={handleIncrease}>
                  +
                </button>
              </>
            )}
            <button
              className="addToCart"
              onClick={handleBoth}
              disabled={productAlreadyInCart ? true : false}
            >
              {productAlreadyInCart ? "Already in cart" : "Add to Cart"}
            </button>
            <button className="addToCart" onClick={() => {
              if (product?.business)
                setMailOpen(true)
              else
                toast.error("Could not open send mail form!")
              }}>Send Message</button>
          </div>
        </div>
        <div className="description-pro desc-check">
          <h2>Description:</h2>
          <p>{product?.description}</p>
        </div>
        <div className="btn-pro">
          {/* <Link to="/preview">
            <button onClick={setOrder}>Order Now</button>
          </Link> */}
        </div>
      </div>
    </div>
    </>
  );
};

export default Checkout;
