import React, { useState, useEffect } from "react";
import "./BusDashboard.css";
import { AiFillDashboard } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { MdProductionQuantityLimits } from "react-icons/md";
import { HiOutlineLogout } from "react-icons/hi";
import { IoMdContact, IoMdSettings } from "react-icons/io";
import { TbBusinessplan } from "react-icons/tb";
import { LiaStoreSolid } from "react-icons/lia";
import Wallet from "../Panels/Wallet/Wallet";
import Product from "../Panels/Product/Product";
// import SendPayment from "../Panels/SendPayment/SendPayment";
import Profile from "../Panels/Profile/Profile";
// import RequestPay from "../Panels/RequestPayment/RequestPay";
// import Withdraw from "../Panels/Withdraw/Withdraw";
import Bcredit from "../Panels/Bcredit/Bcredit";
import Setting from "../Panels/Setting/Setting";
import { Link } from "react-router-dom";
import logo from "../assets/digitalelogo.png";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Gtranslation from "../Panels/Gtranslation/Gtranslation";
import useBusinessSignup from "../../hooks/useBusinessSignup";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import PayoutForm from "../Panels/PayoutForm/PayoutForm";

const FlexItemTwo = () => {
  const [showComp, setShowComp] = useState(1);
  const [divVisible, setDivVisible] = useState(true);
  const { getCurrentBusiness } = useBusinessSignup()
  const [business, setBusiness] = useState({})
  useEffect(() => {
    getCurrentBusiness().then(response => {
      setBusiness(response.data.data)
    }).catch(err => {
      toast.error(err.message)
    })
  },
   [])
  console.log(business)
  const { t, i18n } = useTranslation();

  function handleClick(buttonId) {
    setShowComp(buttonId);
    if (buttonId == 0)
      setDivVisible(true)
    else
    setDivVisible(false);
  }
  return (
    <>
      {/* {showComp === 4 && <SendPayment />} */}
      {showComp === 2 && <Wallet />}
      {showComp === 3 && <Product />}
      {/* {showComp === 5 && <RequestPay />} */}
      {/* {showComp === 6 && <Withdraw />} */}
      {showComp === 7 && <Bcredit />}
      {showComp === 8 && <Setting />}
      {showComp === 9 && <PayoutForm />}
      {divVisible && (
        <div className="mainP panel">
          <div style={{display:"flex", width: "100%"}}>
            <h2>
              Digitale Dashboard
            </h2>
            <div style={{marginLeft: "auto", display:"flex", columnGap: "10px"}}>
            <h2>Payment Due: {business.website_charge || 0} COP </h2>
            <div style={{display: "inline", alignSelf: "center"}}><Button variant="contained" onClick={() => handleClick(9)}>Payout</Button></div>
            </div>
          </div>
          <hr />
          <div className="panel-list">
            <div
              className="panel-items"
              onClick={() => {
                handleClick(2);
              }}
            >
              <FaWallet className="more-icons wallet" />
              <button>
                Wallet (Balance)
              </button>
            </div>
            <div
              className="panel-items"
              onClick={() => {
                handleClick(3);
              }}
            >
              <MdProductionQuantityLimits className="more-icons product" />
              <button>Create Products</button>
            </div>
            <div
              className="panel-items"
              onClick={() => {
                handleClick(7);
              }}
            >
              <TbBusinessplan className="more-icons credit" />
              <button>Orders</button>
            </div>
            <Link className="panel-items" to="/restaurants">
              <LiaStoreSolid className="more-icons credit" />
              <button>Browse Stores</button>
            </Link>
            {/* <div className="panel-items">
            <BsFillSendCheckFill className='more-icons send' />
            <button onClick={() => { handleClick(4) }}>Send Crypto</button>
          </div>
          <div className="panel-items">
            <MdPayments className='more-icons request' />
            <button onClick={() => { handleClick(5) }}>Request Crypto</button>
          </div>
          <div className="panel-items">
            <MdAccountBalance className='more-icons withdraw' />
            <button onClick={() => { handleClick(6) }}>Withdraw Crypto</button>
          </div> */}
            <div
              className="panel-items"
              onClick={() => {
                handleClick(8);
              }}
            >
              <IoMdSettings className="more-icons setting" />
              <button>Settings</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const BusDashboard = () => {
  const [t, i18n] = useTranslation();
  const [showComp, setShowComp] = useState(1);
  const [showBtn, setShowBtn] = useState(false);
  const { Logout, currentUser } = useAuth();
  var user = currentUser();
  const navigate = useNavigate();
  const toggleButtons = () => {
    setShowBtn((prevShowBtn) => !prevShowBtn);
  };

  function handleClick(buttonId) {
    setShowComp(buttonId)
  }

  useEffect(() => {
    let timeoutId;
    let inactivity_minute = 5;
    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // Redirect the user after 2 minutes of inactivity
        window.location.href = "/"; // Replace with your target URL
      }, inactivity_minute * 1000 * 60); // 2 minutes in milliseconds
    };

    const handleActivity = () => {
      resetTimer();
    };

    // Set up event listeners to reset the timer on user activity
    document.addEventListener("click", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("scroll", handleActivity);

    // Start the initial timer
    resetTimer();

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener("click", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("scroll", handleActivity);
      clearTimeout(timeoutId);
    };
  }, []);

  if (user?.user_type != "business") {
    setTimeout(() => {
      navigate("/");
    }, 5000);
    return <p>Forbidden... Redirecting to homepage in 5 seconds</p>;
  }
  const handleLanguageChange = (language_code) => {
    // i18n.changeLanguage(language_code)
    console.log(language_code);
    // window.window.TRANSLATE_PAGE_TO(language_code)
  };

  return (
    <>
      {/* <div className='gtranslate_wrapper'></div> */}
      <div className="FullPage">
        <div className="main-board">
          <div className="item-1" onClick={() => handleClick(1)}>
            <AiFillDashboard className="main-icons dash" />
            <Link onClick={() => window.window.location.reload(false)}><h3>Dashboard</h3></Link>
          </div>
          <div className="item-mid">
            <h3>Digitale Username: {user.email} </h3>
          </div>
          <div className="item-2">
            <div className="profile">
              <IoMdContact
                className="profile-icon"
                onClick={() => handleClick(9)}
              />
              {/* <Gtranslation/> */}
            </div>
          </div>
        </div>
        <div className="Dashboard">
          <div className="Flex-1">
            <div
              className="navbar"
              onMouseEnter={toggleButtons}
              onMouseLeave={toggleButtons}
            >
              <div className="nav-icons">
                <FaWallet className="panel-icons balance" />
                <MdProductionQuantityLimits className="panel-icons product" />
                <TbBusinessplan className="panel-icons credit" />
                {/* <BsSendFill className='panel-icons send' />
              <MdPayments className='panel-icons links' />
              <MdAccountBalance className='panel-icons withdraw' /> */}
                <IoMdSettings className="panel-icons setting" />
                <LiaStoreSolid className="panel-icons credit" />

                <HiOutlineLogout className="panel-icons out" />
              </div>
              <div className={`nav-buttons ${showBtn ? "active" : ""}`}>
                <button
                  onClick={() => {
                    handleClick(2);
                  }}
                >
                  Wallet (Balance)
                </button>
                <button
                  onClick={() => {
                    handleClick(3);
                  }}
                >
                  Create Products
                </button>
                <button
                  onClick={() => {
                    handleClick(7);
                  }}
                >
                  Orders
                </button>
                {/* <button onClick={() => { handleClick(4) }}>Send Crypto</button>
              <button onClick={() => { handleClick(5) }}>Request Crypto</button>
              <button onClick={() => { handleClick(6) }}>Withdraw Crypto</button> */}
                <button
                  onClick={() => {
                    handleClick(8);
                  }}
                >
                  Settings
                </button>

                <button>
                  <Link to="/restaurants" className="color-link">
                    Browse Stores
                  </Link>
                </button>
                <button
                  className="color-link"
                  onClick={() => {
                    Logout();
                    navigate("/");
                  }}
                >
                  Logout
                </button>
                <div className="panel-logo">
                  <img src={logo} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="Flex-2">
            {showComp === 1 && <FlexItemTwo />}
            {showComp === 2 && <Wallet />}
            {showComp === 3 && <Product />}
            {/* {showComp === 4 && <SendPayment />} */}
            {/* {showComp === 5 && <RequestPay />} */}
            {/* {showComp === 6 && <Withdraw />} */}
            {showComp === 7 && <Bcredit />}
            {showComp === 8 && <Setting />}
            {showComp === 9 && <Profile />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusDashboard;
