const checkIfProductIsAlreadyInCart = (product_id) => {
    var cart = JSON.parse(localStorage.getItem("cartItems")) || []
    for (let item of cart) {
        if (item.id == product_id)
            return product_id 
    }
    return false;
}

const getBusinessBitcoinAddressFromCart = () => {
    var cart = JSON.parse(localStorage.getItem("cartItems")) || []
    if (cart.length == 0)
        return null
    return cart[0]?.business?.bitcoin_address
}

export {checkIfProductIsAlreadyInCart, getBusinessBitcoinAddressFromCart}