import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import Gtranslation from "../Panels/Gtranslation/Gtranslation";

const CustomerLayout = ({children}) => {
  const cartItems = useSelector(state => state.cart)
  const { sendGet } = useApi();
  const [businesses, setBusinesses] = useState([]);
  useEffect(() => {
    sendGet("/businesses")
      .then((response) => {
        console.log("business: ", response.data.data);
        response.data.data.forEach((item) => {
          setBusinesses((businesses) => [...businesses, item]);
        });
      })
      .catch((err) => {
        console.log("business get error: ", err.message);
      });
  }, []);
  return (
    <>
    <div className="Resturant-panel">
    {/* <Gtranslation/> */} 
      <div className="upper-body">
        <div className="rest-nav">
          <div className="links">
            <Link to="/">
              <span>Home</span>
            </Link>
            <Link to="/restaurants">
              <span>Browse Restaurants</span>
            </Link>
            <Link to="/my-orders">
              <span>My Orders</span>
            </Link>
          </div>
          <div>
            <div className="searchAndCat">
              <div className="search-item">
                <BsSearch className="searchicon" /> <br />
                <input type="text" placeholder="Search" />
              </div>
              <div className="cat-item">
                <select>
                  <option value="">Product Category</option>
                  <option value="">Sides</option>
                  <option value="">Desserts</option>
                  <option value="">Regular Drinks</option>
                  <option value="">Entree</option>
                  <option value="">Alcohol Drinks</option>
                </select>
              </div>
            </div>
          </div>
          <Link className="cart-icon" to="/my-cart">
            <div className="cart-circle">
              <span>{cartItems.length}</span>
            </div>
            <AiOutlineShoppingCart classNamecart-icon />
          </Link>
        </div>
      </div>
      <div className="content">
        {children}
      </div>
    </div>
    </>
  );
};

export default CustomerLayout;
