import React, { useState } from 'react'
// import food from '../assets/pro2.jpg'
import './Checkout.css'
import { Link } from 'react-router-dom'
import { BsFillHeartFill } from 'react-icons/bs'
import { FaShareAlt } from 'react-icons/fa'
import { FaRegFaceLaugh, FaRegFaceMeh, FaRegFaceTired } from 'react-icons/fa6'
import { AiOutlineShoppingCart } from 'react-icons/ai'
// import {AiOutlineCloseCircle} from 'react-icons/ai'

const Checkout2 = () => {
    const [quantity, setQuantity] = useState(0);
    const [cart, setCart] = useState(0);

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleIncrease = () => {
        setQuantity(quantity + 1);
    };
    const handleBoth = () => {
        setCart(quantity)
    }

    return (
        <div className="checkout">
            <div className="products-pro">
                <div className="pro-1">
                    <div className="image-pro">
                        {/* <img src={food} alt="" /> */}
                    </div>
                    <div className="details-pro">
                        <div className="icon-check">
                            <BsFillHeartFill className='heart-icon-check' />
                            <div className="heart-count-check">1</div>
                            <FaShareAlt className="shared" />
                        </div>
                        <div className="check-det">
                            <div>
                                <span>Restaurant:</span>
                                <span>Kitchen King</span>
                            </div>
                            <div>
                                <span>Product Category:</span>
                                <span>Entree</span>
                            </div>
                            <div>
                                <span>Product Name:</span>
                                <span>Shrimp Pasta</span>
                            </div>
                            <div>
                                <span>Product Description:</span>
                                <span>A Linguini Pasta with Shrimp</span>
                            </div>
                            <div>
                                <span>Price:</span>
                                <span>70.000 COP$</span>
                            </div>
                            <div>
                                <span>Order Method:</span>
                                <span>Pickup</span>
                            </div>
                        </div>
                    </div>
                    <div className="review">
                        <div className="heading-review">How is your experience?</div>
                        <div className="review-flex">
                            <div>
                                <FaRegFaceLaugh className='face happy' />
                                <span className='happy'>Excellent</span>
                            </div>
                            <div>
                                <FaRegFaceMeh className='face okay' />
                                <span className='okay'>Okay</span>
                            </div>
                            <div>
                                <FaRegFaceTired className='face sad' />
                                <span className='sad'>Not Good</span>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="cart-icon">
                    <div className="cart-circle"><span>{cart}</span></div>
                    <AiOutlineShoppingCart classNamecart-icon />
                </div>
                <div className="quantity">
                    <div className="counter">
                        <button className="quantity-button" onClick={handleDecrease}>
                            -
                        </button>
                        <span className="quantity">{quantity}</span>
                        <button className="quantity-button" onClick={handleIncrease}>
                            +
                        </button>
                        <button className="addToCart" onClick={handleBoth}>Add to Cart</button>
                    </div>
                </div>
                <div className="description-pro desc-check">
                    <h2>Description:</h2>
                    <p>
                        Linguini Pasta with Shrimp and Vegetables is not only satisfying but also a feast for the eyes. Whether served as a light lunch or a hearty dinner option, this dish is sure to impress family and friends and leave them craving for more. It's a celebration of Italian cuisine with a touch of elegance and a burst of delightful tastes that will make it an instant favorite at any dining table.
                    </p>
                </div>
                {/* <div className="btn-pro">
                    <Link to='/preview'><button>Order Now</button></Link>
                </div> */}
            </div>
        </div>

    );
}

export default Checkout2