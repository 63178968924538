import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { UserToken } from "./useApi";

const API_ROOT = process.env.REACT_APP_API_HOST;

export default function useAuth() {

    const authTokenKey = "AuthToken";
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        verifyLoggedIn();
    }, []);


    const verifyLoggedIn = () => {
        const token = getToken();
        return true;
    }

    const saveToken = (token) => {
        window.localStorage.setItem(authTokenKey, JSON.stringify(token));
    }

    const removeUser = () => {
        window.localStorage.removeItem("user")
        window.localStorage.removeItem("token")
    }

    const getToken = () => {
        // var savedToken = JSON.parse(window.localStorage.getItem(authTokenKey, ""));
        var savedToken = localStorage.getItem("token")
        return savedToken;
    }

    const Logout = () => {
        removeUser();
        
        // saveToken("");
    }

    const currentUser = () => {
        var user = JSON.parse(localStorage.getItem("user"))
        return user
    }

    return { isLoggedIn, loading, Logout,getToken,verifyLoggedIn, currentUser};
}
