import React, { useEffect, useState } from 'react'
import useApi from './useApi'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { UserTypes } from '../const';


export default function useLogin() {

    const { sendGet, sendPostFormData } = useApi({ fetchOnInit: false });

    const [otpSent, setOtpSent] = useState(false);

    const [sending, setSending] = useState(false);

    const navigate = useNavigate();

    const sendOtp = (
        email, userType
    ) => {

        const formData = new FormData();
        formData.append("email", email);
        formData.append("user_type", userType);

        setSending(true);
        return sendPostFormData('/sendotp', formData, {isAuth: false}).then((data) => {
            console.log(data);
            setOtpSent(true);
            toast.success("otp sent successfully");
            setSending(false);
        }).catch((err) => {
            console.log(err);
            toast.error("Email doesn't exist in our database");
            setSending(false);
        });
    }

    const loginWithOtp = (email, otp, userType) => {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("user_type", userType);
        formData.append("otp", otp);
        setSending(true);

        return sendPostFormData('/login', formData, {isAuth: false}).then((response) => {
            setSending(false);
            localStorage.setItem("user", JSON.stringify(response.data.data.user))
            localStorage.setItem("token", response.data.data.token)
            if (userType == UserTypes.Business) {
                navigate("/dashbus")
            }else{
                navigate("/restaurants")
            }
        }).catch((err) => {
            console.log(err);
            toast.error("failed to login, pls try again");
            setSending(false);
        });

    }

    return { sendOtp, otpSent, sending , loginWithOtp}
}
