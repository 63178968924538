import React from "react";
import "./Landing.css";
import logo from "../assets/digitalelogo.png";
import { Link } from "react-router-dom";
import Gtranslation from "../Panels/Gtranslation/Gtranslation";

const linkStyle = {
  width: "100%",
};
const Landing = () => {
  return (
    <>
      {/* <Gtranslation/> */}
      <div className="landing">
        <div className="container">
          <div className="image">
            <img src={logo} alt="" />
          </div>
          <div className="text">
            <h1>Digitale</h1>
            <p>
              Accept Digital Payments for your Restaurant, Grocery
              Store, or Business.
            </p>
          </div>
          <div className="buttons">
            <Link to="/business" style={linkStyle}>
              <button className="btn-business">Join as Business</button>
            </Link>
            {/* <Link to="/individual" style={linkStyle}><button className="btn-individual">Join as Individual</button></Link> */}
            <Link to="/login" style={linkStyle}>
              <button className="btn-login">Businesses Login Here</button>
            </Link>
            <Link to="/restaurants" style={linkStyle}>
              <button className="browse-stores">Browse Stores</button>
            </Link>
            <Link to="/CusLogin" style={linkStyle}>
              <button className="btn-login">Customers Login Here</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
