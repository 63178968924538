import React, { useEffect, useState } from 'react'
import useApi from './useApi'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { UserTypes } from '../const';

export default function useIndividualSignup() {

    const { sendGet, sendPostFormData } = useApi({ fetchOnInit: false });

    const [inProcess, setInProcess] = useState(false);

    const navigate = useNavigate();

    const signup = (
        name, email, phoneNumber, cedula, confirm_cedula, country, bdate
    ) => {
        setInProcess(true);

        const formData = new FormData();

        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phoneNumber);
        formData.append("cedula", cedula);
        formData.append("confirm_cedula", confirm_cedula);
        formData.append("country", country);
        formData.append("bdate", bdate);
        formData.append("user_type", UserTypes.Individual);


        return sendPostFormData('/individualregister', formData).then((data) => {
            console.log(data);
            navigate("/login")
            setInProcess(false);
        }).catch((err) => {
            console.log(err);
            toast.error("failed to signup");
            setInProcess(false);
        });
    }

    return { signup, inProcess }
}
