import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, InstapaperShareButton, InstapaperIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
const emails = ['username@gmail.com', 'user02@gmail.com'];

export default function({open, setOpen}) {
  

  const handleClose = () => {
    setOpen(false)
  };

  const handleListItemClick = (value) => {
    // onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="500px" maxWidth="sm" fullWidth>
      <DialogTitle style={{paddingBottom: 0}}><h2 style={{margin: "0"}}>Share</h2></DialogTitle>
      <List sx={{width: "100%", margin:"0", paddingTop:0}}>
        {/* {emails.map((email) => ( */}
          <ListItem disableGutters style={{padding: "0 25px", marginBottom:"20px"}}>
            {/* <ListItemButton disableGutters> */}
            <FacebookShareButton url={window.location} style={{display: "flex", alignItems:"center", padding: "10px", columnGap:"20px"}} >
                  <FacebookIcon round />
                  <h4 style={{color: "white"}}>Share to Facebook</h4>
            </FacebookShareButton>
            {/* </ListItemButton> */}
          </ListItem>
        {/* ))} */}
        <ListItem disableGutters style={{padding: "0 25px", marginBottom:"20px"}}>
            {/* <ListItemButton disableGutters> */}
            <TwitterShareButton url={window.location} style={{display: "flex", alignItems:"center", padding: "10px", columnGap:"20px"}} >
                  <TwitterIcon round />
                  <h4 style={{color: "white"}}>Share to Twitter</h4>
            </TwitterShareButton>
            {/* </ListItemButton> */}
          </ListItem>
          <ListItem disableGutters style={{padding: "0 25px", marginBottom:"20px"}}>
            {/* <ListItemButton disableGutters> */}
            <WhatsappShareButton url={window.location} style={{display: "flex", alignItems:"center", padding: "10px", columnGap:"20px"}} >
                  <WhatsappIcon round />
                  <h4 style={{color: "white"}}>Share to Whatsapp</h4>
            </WhatsappShareButton>
            {/* </ListItemButton> */}
          </ListItem>
      </List>
    </Dialog>
  );
}
