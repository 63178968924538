import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import Autocomplete from "react-google-autocomplete";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { FaEnvelope } from "react-icons/fa6";
import { UserTypes } from "../../const";
import useBusinessSignup from "../../hooks/useBusinessSignup";
import bg from "../assets/bg.png";
import person1 from "../assets/person1.png";
import person2 from "../assets/person2.png";
import person3 from "../assets/person3.png";
import "./Business.css";
import { toast } from "react-toastify";
import { firstWordUpperCase } from "../../helpers/StringHelper";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from "dayjs";
import moment from "moment";
import Gtranslation from "../Panels/Gtranslation/Gtranslation";

const Business = () => {
  const { register, handleSubmit } = useForm();
  const apiKey = process.env.REACT_APP_GMAP_API_KEY;

  const { signup, inProcess } = useBusinessSignup();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [business_name, setBusinessName] = useState("");
  const [opening_hour, setOpeningHour] = useState("");
  const [closing_hour, setClosingHour] = useState("");

  const onSubmit = (data) => {
    data = { ...data, opening_hour: opening_hour, closing_hour: closing_hour };
    // console.log(data);
    // return;
    const formData = new FormData();
    if (!selectedPlace) {
      toast.error("please select the address");
      return;
    }

    if (!data.checkBox) {
      toast.error("please accept terms and conditions");
      return;
    }

    if (!data.checkBoxCommision) {
      toast.error("Please accept the commision terms");
      return;
    }

    if (!data.country) {
      toast.error("please select a country");
      return;
    }

    formData.append("first_name", data.firstName);
    formData.append("last_name", data.lastName);
    formData.append("business_name", data.businessName);

    formData.append("business_logo", data.businessLogo[0]); // Assumes a single file upload
    formData.append("business_type", data.businessType);
    formData.append("business_description", data.businessDescription);
    formData.append("opening_hour", data.opening_hour);
    formData.append("closing_hour", data.closing_hour);
    formData.append("email", data.email);

    formData.append("country", data.country);
    formData.append("business_id", data.businessId); // Corrected typo in field name
    formData.append("address", selectedPlace);
    formData.append("user_type", UserTypes.Business);

    signup(formData);
  };

  const handlePlaceSelect = (data) => {
    setSelectedPlace(data.formatted_address);
  };

  return (
    <>
      <Gtranslation />
      <div className="business-form">
        <div className="person-1">
          <img src={person1} alt="" />
        </div>
        <div className="person-2">
          <img src={person2} alt="" />
        </div>
        <div className="person-3">
          <img src={person3} alt="" />
        </div>
        <div className="bg">
          <img src={bg} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="Main-title">
            <h1>Digitale - Sign Up</h1>
            <p>Please type your credentials</p>
          </div>
          <div>
            <label htmlFor="fname">
              First name <sup>*</sup>
            </label>{" "}
            <br />
            <input
              id="fname"
              type="text"
              required
              placeholder="Your first legal name"
              {...register("firstName")}
            />
          </div>
          <div>
            <label htmlFor="lname">
              Last name <sup>*</sup>
            </label>{" "}
            <br />
            <input
              id="lname"
              type="text"
              required
              placeholder="Your last legal name"
              {...register("lastName")}
            />
          </div>
          <div>
            <label htmlFor="bname">
              Business name <sup>*</sup>{" "}
            </label>{" "}
            <br />
            <input
              id="bname"
              type="text"
              required
              placeholder="Your business name"
              value={business_name}
              // name="businessName"
              {...register("businessName")}
              onChange={(e) => {
                setBusinessName(
                  firstWordUpperCase(e.target.value.toLowerCase())
                );
              }}
            />
          </div>
          <div>
            <label htmlFor="bdescription">
              Business Description <sup>*</sup>{" "}
            </label>{" "}
            <br />
            <textarea
              id="bdescription"
              placeholder="Tell us more about your business."
              {...register("businessDescription")}
            ></textarea>
          </div>
          <div>
            <label htmlFor="bhour">
              Business Hours <sup>*</sup>
            </label>{" "}
            <br />
            {/* <input
            id="bhour"
            type="text"
            placeholder="Your business hours"
            {...register("businessHour")}
          /> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="timepicker-container">
                <TimePicker
                  label="Opening"
                  slotProps={{ textField: { size: "small", required: true } }}
                  onChange={(e) => {
                    console.log(moment(e).format("h:mm a"));
                    setOpeningHour(moment(e).format("h:mm a"));
                  }}
                />
                <span>-</span>
                <TimePicker
                  label="Closing"
                  slotProps={{ textField: { size: "small", required: true } }}
                  onChange={(e) => {
                    console.log(moment(e).format("h:mm a"));
                    setClosingHour(moment(e).format("h:mm a"));
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
          <div>
            <label htmlFor="bname">
              Upload Business Logo <sup>*</sup>
            </label>{" "}
            <br />
            <input
              id="bname"
              type="file"
              placeholder="Your business name"
              required
              {...register("businessLogo")}
            />
          </div>
          <div>
            <label htmlFor="bcat">Business type </label> <br />
            <select
              name="bcat"
              id="bcat"
              {...register("businessType")}
              required
            >
              <option value="">Type</option>
              <option value="resturant" selected>
                Restaurant{" "}
              </option>
              <option value="grocery" disabled>
                Grocery Store{" "}
                <small className="coming">
                  <strong>~Coming Soon</strong>
                </small>
              </option>
              <option value="jewelry" disabled>
                Jewelry{" "}
                <small className="coming">
                  <strong>~Coming Soon</strong>
                </small>
              </option>
            </select>
          </div>
          <div>
            <label htmlFor="email">
              Business Email <sup>*</sup>
              <span className="small">
                (Business Email is your Digitale Username)
              </span>
            </label>{" "}
            <br />
            <div className="with-icon">
              <FaEnvelope className="icon" />
              <input
                id="email"
                type="email"
                required
                placeholder="example@company.com"
                {...register("email")}
              />
            </div>
          </div>
          <div>
            <label htmlFor="country">
              Country <sup>*</sup>
            </label>{" "}
            <br />
            <select
              name="country"
              id="country"
              placeholder="Country"
              {...register("country")}
            >
              <option value="">Country</option>
              <option value="colombia" selected>
                Colombia
              </option>
            </select>
          </div>
          <div>
            <label htmlFor="bday">Business Identification #</label> <br />
            <input
              id="bday"
              type="text"
              placeholder="Insert Rut/NIT #"
              {...register("businessId")}
            />
          </div>
          <div className="App">
            <label>Address</label>
            <GooglePlacesAutocomplete
              apiKey={apiKey}
              onPlaceSelected={handlePlaceSelect}
              options={{
                types: "city",
              }}
            />
          </div>
          <div className="text-check">
            <input
              className="check"
              type="checkbox"
              {...register("checkBox")}
            />
            <span>
              I Agree to
              <a
                target="_blank"
                href="https://www.docdroid.net/rawVjvg/cryptoshare-terms-and-conditions-pdf"
              >
                {" "}
                Terms & Conditions,
              </a>
              <a
                target="_blank"
                href="https://www.docdroid.net/5gwsBLX/anti-money-laundering-cryptosharepay-1-1-pdf"
              >
                AML,
              </a>
              and{" "}
              <a
                target="_blank"
                href="https://www.docdroid.net/astXB3F/cryptoshare-privacy-policy-pdf"
              >
                Jurisdiction Policy
              </a>
              .
            </span>
          </div>
          <div className="text-check">
            <input
              className="check"
              type="checkbox"
              {...register("checkBoxCommision")}
            />
            <span>
              PayDigitale charges a Small Commission on Every Purchase.
            </span>
          </div>
          <div>
            <button
              type="submit"
              className="default-button"
              disabled={inProcess}
            >
              {inProcess ? <span>processing...</span> : <span>Sign up</span>}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Business;
