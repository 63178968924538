import React, { useState } from 'react'
// import salad from './components/Panels/assets2/pro1.jpg'
// import food from './components/Panels/assets2/pro2.jpg'
import { AiOutlineCloseCircle } from 'react-icons/ai';

import { Link } from 'react-router-dom';
import './Example.css';
import { CgArrowsExchangeAlt } from 'react-icons/cg'

export const Popup = ({product, closePopup}) => {
    const [close, setClose] = useState(false);
    function handleClose(product){
        document.getElementById("popup-" + product.id).style.display = "none"
        // setClose(true);
        // closePopup()
    }
    return (
       <> { !close ?  <div className="popup" id={"popup-" + product.id} key={"popup-" + product.id}>
            <div className="products-pro">
                <div className="pro-1">
                    <div className="image-pro">
                        <img src={product.image_url} alt="" />
                    </div>
                    <div className="details-pro">
                        <div className="cat-pro">
                            <h4>Product Category:</h4>
                            <span>{product.category}</span>
                        </div>
                        <span className='classic'>{product.category}</span>
                        <div className="pro-pro">
                            <h4>Product:</h4>
                            <span>{product.name}</span>
                        </div>
                        <div className="price-pro">
                            <h4>Price:</h4>
                            <span>{product.price} COP$</span>
                            {/* <span>=</span>
                            <span>17.07 XRP</span> */}
                        </div>
                        <div className="method">
                            <h4>Order Method:</h4>
                            <span>{product.order_method}</span>
                        </div>
                    </div>
                    <div className="close-container">
                        <AiOutlineCloseCircle className="close" onClick={() => {handleClose(product)}} />
                    </div>
                </div>
                <div className="description-pro">
                    <h4>Description:</h4>
                    <p>
                        {product.description}
                    </p>
                </div>
                {/* <div className="btn-pro">
                    <Link to='/preview'><button>Order Now</button></Link>
                </div> */}
            </div>
        </div> : null} </>

    );
}
export const Popups = () => {
    const [close, setClose] = useState(false);
    function handleClose(){
        setClose(true);
    }
    return (
        <>{ !close ? <div className="popups">
            <div className="products-pro">
                <div className="pro-1">
                    <div className="image-pro">
                        {/* <img src={food} alt="" /> */}
                    </div>
                    <div className="details-pro">
                        <div className="cat-pro">
                            <h4>Product Category:</h4>
                            <span>Entree</span>
                        </div>
                        <span className='classic'>Shrimp Pasta</span>
                        <div className="pro-pro">
                            <h4>Product:</h4>
                            <span>Linguini Pasta with Shrimp <br /> and Vegetables</span>
                        </div>
                        <div className="price-pro">
                            <h4>Price:</h4>
                            <span>70.000 COP$</span>
                            <span>=</span>
                            <span>22.35 XRP</span>
                        </div>
                        <div className="method">
                            <h4>Order Method:</h4>
                            <span> Pickup</span>
                        </div>
                    </div>
                    <div className="close-container">
                        <AiOutlineCloseCircle className="close" onClick={handleClose} />
                    </div>
                </div>
                <div className="description-pro">
                    <h4>Description:</h4>
                    <p>
                        Linguini Pasta with Shrimp and Vegetables is not only satisfying but also a feast for the eyes. Whether served as a light lunch or a hearty dinner option, this dish is sure to impress family and friends and leave them craving for more. It's a celebration of Italian cuisine with a touch of elegance and a burst of delightful tastes that will make it an instant favorite at any dining table.
                    </p>
                </div>
                {/* <div className="btn-pro">
                    <Link to='/preview'><button >Order Now</button></Link>
                </div> */}
            </div>
        </div>: null}
        </>
    )
}

const Example = () => {

    const [amount, setAmount] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('SELECT');
    const [converted, setConverted] = useState('');
    const exchangeRates = {
        COP: 1,
        BTC: 120110737.10,
        ETH: 7640512.18,
        XRP: 3130.66,
    };
    const handlePriceChange = (price) => {
        setAmount(price);
        const convertedValue = price / exchangeRates[selectedCurrency];
        setConverted(convertedValue);
    }
    const handleCurrencyChange = (currency) => {
        setSelectedCurrency(currency);
        // Calculate the converted price based on the selected currency
        const convertedValue = amount / exchangeRates[currency];
        setConverted(convertedValue);
    };
    return (
        <div className="coversion">
            <label htmlFor="con">Conversion</label>
            <div className="con-inputs">
                <div className="COP">
                    <input type="number" value={amount} onChange={(e) => handlePriceChange(e.target.value)} />
                    <label htmlFor="">COP$</label>
                </div>
                <div className="con-icon">
                    <CgArrowsExchangeAlt className='con-change' />
                </div>
                <div className="converted-amount">
                    {<span>{converted}</span>}
                </div>
                <div className="other-cons">

                    <select value={selectedCurrency} onChange={(e) => handleCurrencyChange(e.target.value)}>
                        <option value="BTC">BITCON</option>
                        <option value="ETH">ETH</option>
                        <option value="XRP">XRP</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default Example